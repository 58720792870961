import useAuth from '../../hooks/useAuth';

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();

    if (isLoggedIn) {
        return '';
    }

    return children;
};

export default GuestGuard;
