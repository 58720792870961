import {makeStyles} from "@material-ui/styles";

export const useAgreementsStyles = makeStyles((theme: any) => ({
    documents: {
        '& h1': {
          fontSize: '1.7rem'
        },
        '& ol:not(.alpha)': {
            listStyleType: 'none',
            counterReset: 'item',
            margin: 0,
            padding: 0
        },
        '& ol:not(.alpha) > li': {
            display: 'table',
            counterIncrement: 'item',
            marginBottom: '0.6em'
        },
        '& ol:not(.alpha) > li:before': {
            content: 'counters(item, ".") ". "',
            display: 'table-cell',
            paddingRight: '0.6em'
        }
    }
}));