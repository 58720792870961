import {makeStyles} from "@material-ui/styles";

export const useNotificationBarStyles = makeStyles((theme: any) => ({
    container: {
      minHeight: '3rem',
      background: '#BB1B7B',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 1px 8px 0 rgb(0 0 0 / .1), 0 1px 7px -1px rgb(0 0 0 / .1)',
      zIndex: 9999,
      top: 0,
      position: 'sticky',
      padding: '.5rem'
    },
    icon:{
        color: '#fff'
    },
    message: {
        color: '#fff'
    },
    okButton: {
        backgroundColor: '#000',
        '&:hover': {
            backgroundColor: '#000',
        },
    }
}));