import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from "react-ga4";
import MainRoutes from './MainRoutes';
import AuthRoutesLoggedOut from './AuthRoutesLoggedOut';
import AuthRoutesLoggedIn from './AuthRoutesLoggedIn';
import useAuth from './../hooks/useAuth';
import Onboarding from "../shared/components/onboarding/onboarding";
import {ConfigProvider} from "react-avatar";
import {useTheme} from "@material-ui/core";


//-----------------------|| ROUTING RENDER ||-----------------------//
        
const Routes = () => {


    const {user, init} = useAuth(); 
    const history = useHistory();
    const theme = useTheme();

    const multiTabLogout = (e) => {
        if(e.key === 'refreshToken' && e.oldValue && !e.newValue) {
            init();
        }
    };

    
   
   /* Appcues tracking */

    useEffect(() => { 
        if (user && window.Appcues) {
            window.Appcues.identify(
                user.cognitoSub,
                {
                    firstName : user.firstName,
                    email : user.email,
                    role : user.role
                }
            );
        }
        
    },[user]);

    useEffect(() => {
      return history.listen((location) => { 
        if (window.Appcues) {
            window.Appcues.page();
        }
        
      }) 
    },[history]);

    /* GA pageviews */
    useEffect(() => {
        ReactGA.send('pageview');
        return history.listen((location) => { 
            ReactGA.send('pageview');
            
        }); 
        
    }, [history]);

    /* Log out multitab */

    useEffect(() => {
        window.addEventListener('storage', multiTabLogout);
        return () => {
            window.removeEventListener('storage', multiTabLogout);
        }
    });

    return (
            
            
        <React.Fragment>
            {/*<Redirect exact from="/" to={config.defaultPath} />*/}


            {/* Route for login, sign, reset password etc */}
            <AuthRoutesLoggedOut />
            
            {/* Routes for 1-registration */}
            <AuthRoutesLoggedIn />

            <Onboarding />
            

            {/* Routes for main layouts inc 404 */}

            <ConfigProvider colors={[
                '#fa9500',
                '#2a9d8f',
                '#3a86ff',
                '#ff006e',
                '#3a0ca3']}>
            <MainRoutes />
            </ConfigProvider>


        </React.Fragment>


            
    );
};

export default Routes;
