import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {  useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
    Divider,
    ListItemIcon,
    ListItemText,
    Typography,
    CircularProgress, Button, Menu, Fade, Box, MenuItem, Stack
} from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import {
    ArrowDropDown, Event,
    LogoutOutlined, ManageAccountsOutlined, PersonOutline,
    SettingsOutlined, InfoOutlined, Delete
} from "@material-ui/icons";
import Avatar from "react-avatar";
import {UserPermissionTypesEnum} from "../../../../shared/permissions/enums/user-permission-types.enum";
import {UserPermissionsEnum} from "../../../../shared/permissions/enums/user-permissions.enum";
import PermissionsContext from "../../../../contexts/permissions.context";
import config from "../../../../config";
import {RoleEnum} from "../../../../shared/enums/role.enum";
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import {AgreementsEnum} from "../../../../shared/components/agreements/agreements.enum";
import {AgreementsContext} from "../../../../contexts/agreements.context";
import {isMobile} from "react-device-detect";
import RoleRestricted from "../../../../utils/component-guard/RoleRestricted";

// style const
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvatar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important',
        //color:'white !important'
        background:'none'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main + '!important',
            color: theme.palette.primary.light,
            '& svg': {
                stroke: theme.palette.primary.light
            }
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
        color: '#fff'
    },
    profileItem: {
        color: "#000",
        '& .MuiButton-endIcon': {
            marginLeft: '0'
        }
    },
    navItem: {
        //color: theme.palette.grey[900]
        color: "#000"
    },
    menu: {
        // maxHeight: '25rem',
        maxWidth: '55rem'
    },
    navLink: {
        textDecoration: "none",
        color: 'inherit'
    },
    largeAvatar: {
        padding: '1rem 1rem 1.4rem'
    },
    isHiddenMobile:{
        display: 'initial',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    isShownMobile:{
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'initial',
        }
    }
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {hasPermissions, hasParentRole} = useContext(PermissionsContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const { logout, user } = useAuth();
    const open = Boolean(anchorEl);
    const [loggingOut, setLoggingOut] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { showDialog } = useContext(AgreementsContext);
    const handleLogout = async () => {
        setLoggingOut(true);
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>

            <Button id={"profileBtn"} variant="text" endIcon={<ArrowDropDown />} className={classes.profileItem}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}>
                <Avatar aria-label="Profile" name={user.firstName  + ' ' + user.lastName} size={30}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        round=".2rem"/>
            </Button>
            <Menu
                id="trials-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menu}
                MenuListProps={{
                    'aria-labelledby': 'trials-menu-button',
                }}
                TransitionComponent={Fade}
            >
                <Stack direction="row" className={classes.largeAvatar}>
                    <Box>
                        <Avatar aria-label="Profile" name={user.firstName  + ' ' + user.lastName} size={55} textSizeRatio={1}
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                round=".2rem"/>
                    </Box>
                    <Stack direction="column" alignItems="flex-start" justifyContent="center" sx={{ paddingLeft: '1rem'}}>
                        <Typography variant="h4">{user.firstName} {user.lastName}</Typography>
                        <Typography variant="subtitle3">{user.site?.name}</Typography>
                        <Typography variant="subtitle2">{user.email}</Typography>
                    </Stack>
                </Stack>

                <Divider sx={{ marginBottom: '.5rem'}} />

                    <Link to={'/preferences/your-details'} className={[classes.navLink, classes.isShownMobile].join(' ')}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <PersonOutline fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Your details</ListItemText>
                        </MenuItem>
                    </Link>

                    <Link to={'/preferences/preferred-times'} className={[classes.navLink, classes.isShownMobile].join(' ')}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Event fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Preferred contact hours</ListItemText>
                        </MenuItem>
                    </Link>

                    <Link to={'/preferences/delete-account'} className={[classes.navLink, classes.isShownMobile].join(' ')}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Delete fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete Account</ListItemText>
                        </MenuItem>
                    </Link>

                { !hasParentRole([RoleEnum.PARTICIPANT]) &&
                    <MenuItem onClick={() => {
                        window.open(config.help.link)
                    }}>
                        <ListItemIcon>
                            <InfoOutlined fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Contact us</ListItemText>
                    </MenuItem>
                }



                <Divider sx={{ marginBottom: '.5rem'}} className={classes.isShownMobile} />
                <MenuItem onClick={() => {
                  handleClose();
                    showDialog(AgreementsEnum.PrivacyPolicy, true);
                }}>
                  <ListItemIcon>
                      <PolicyOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Privacy policy</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  handleClose();
                    showDialog(AgreementsEnum.TermsOfService, true);
                }}>
                  <ListItemIcon>
                      <ArticleOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Terms of service</ListItemText>
                </MenuItem>


                { isMobile &&  <RoleRestricted role={[RoleEnum.PARTICIPANT]}>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <InfoOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Read the FAQs</ListItemText>
                    </MenuItem>
                </RoleRestricted> }

                <Divider sx={{ marginBottom: '.5rem'}} />     

                { (hasPermissions(UserPermissionTypesEnum.Trial, [UserPermissionsEnum.RemoveUser]) ||
                  hasPermissions(UserPermissionTypesEnum.Company, [UserPermissionsEnum.RemoveUser])) && <Link to={'/settings'} className={classes.navLink}>
                    <MenuItem id={"settingsMenuItem"} onClick={handleClose}>
                        <ListItemIcon>
                            <SettingsOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                    </MenuItem>
                </Link>
                }

                <Link to={'/preferences'} className={[classes.navLink, classes.isHiddenMobile].join(' ')}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <ManageAccountsOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Preferences</ListItemText>
                        </MenuItem>
                </Link>

                <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            {loggingOut && (
                                    <CircularProgress size={20}/>
                                )}
                                {!loggingOut && (
                                    <LogoutOutlined fontSize="small" />
                                )}
                        </ListItemIcon>
                        <ListItemText>Log out</ListItemText>
                    </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default ProfileSection;
