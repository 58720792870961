import React from 'react';
import {NavLink} from 'react-router-dom';
import {Menu, MenuItem, useTheme} from '@material-ui/core';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    IconButton,
    Stack,
    Toolbar,
    Typography,
} from '@material-ui/core';
import Logo from '../../../ui-component/Logo';
import {InfoOutlined, MenuSharp} from "@material-ui/icons";
import ProfileSection from "../../../layout/MainLayout/Header/ProfileSection";
import RoleRestricted from "../../../utils/component-guard/RoleRestricted";
import {RoleEnum} from "../../enums/roleEnum";
import {getNavTrials} from "../../../store/appReducer";
import {useDispatch} from "react-redux";
import {CHECK_ONBOARDING, PREFERENCES_SAVE_FAVOURITES} from "../../../store/actions";
import useAuth from "../../../hooks/useAuth";
import {Divider} from "@mui/material";
import PermissionRestricted from "../../../utils/component-guard/PermissionRestricted";
import NotificationBarComponent from "../notification-bar/notification-bar.component";
import {UserPermissionTypesEnum} from "../../permissions/enums/user-permission-types.enum";
import {UserPermissionsEnum} from "../../permissions/enums/user-permissions.enum";
import {useAppBarStyles} from "./app-bar.styles";
import {AppBarProps} from "./app-bar.props.";
import NotificationPanelComponent from "../notification-panel/components/notification-panel.component";
import { CompanyTypeEnum } from '../../enums/companyTypeEnum';
import {SizeEnum} from "../../enums/size.enum";
import LogoComponent from "./logo.component";

// style constant


const AppBar = (props: AppBarProps, { ...others }) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const theme = useTheme();
    const classes = useAppBarStyles(theme);
    const dispatch = useDispatch();
    const {user} = useAuth() as any;
    const [isCompanySponsor, setIsCompanySponsor] = React.useState(false);

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const onShow = (show: boolean) => {
        props.onNotificationShow(show);
    }

    React.useEffect(() => {
        dispatch({ type: PREFERENCES_SAVE_FAVOURITES, favourites: user.favourites });
        dispatch(getNavTrials(user));
        dispatch({ type: CHECK_ONBOARDING });
        if (user.company) {
            const companyType = user.company.companyType;
            setIsCompanySponsor(companyType === CompanyTypeEnum.SPONSOR);
        }
    },[dispatch, user]);

    return (

        <MuiAppBar className={classes.appBar} elevation={0}>

            <NotificationBarComponent onShow={onShow} />
            <Toolbar sx={{display: { xs: 'flex', md: 'none' }, justifyContent:'space-between'}}>

            <Box>
                <IconButton
                    aria-label="main menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuSharp color="action"  />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >

                    <RoleRestricted role={[RoleEnum.PARTICIPANT]}>
                        <NavLink exact activeClassName='is-active' to="/your-trials" onClick={handleCloseNavMenu} className={classes.navLink}>
                            <MenuItem><Typography textAlign="center">Your trials</Typography></MenuItem>
                        </NavLink>
                    </RoleRestricted>

                    <RoleRestricted role={[RoleEnum.PARTICIPANT]}>
                        <NavLink exact activeClassName='is-active' to="/find-a-trial" onClick={handleCloseNavMenu} className={classes.navLink}>
                            <MenuItem><Typography textAlign="center">Find a trial</Typography></MenuItem>
                        </NavLink>
                    </RoleRestricted>

                </Menu>
            </Box>
                <Typography component="div">
                    <LogoComponent size={SizeEnum.SMALL} />
                </Typography>

                <Stack direction="row" spacing={2}>
                    <ProfileSection />
                </Stack>
            </Toolbar>

            <Toolbar className={classes.toolbar} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Typography component="div" className={classes.logo}>
                    <NavLink exact activeClassName='is-active' to="/" className={classes.navLink}>
                        <Stack direction={"row"} alignItems={"center"} spacing={4}>
                            <LogoComponent size={SizeEnum.LARGE} />

                            {/*<RoleRestricted role={[RoleEnum.PARTICIPANT]}>*/}
                            {/*     <Divider orientation="vertical" variant={"middle"} flexItem className={classes.divider} />*/}
                            {/*</RoleRestricted>*/}
                        </Stack>
                    </NavLink>
                </Typography>



                <Stack direction="row" sx={{ width: "100%", marginLeft: '1rem'}}  spacing={4}>

                    <RoleRestricted role={[RoleEnum.PARTICIPANT]}>
                        <NavLink exact activeClassName='is-active' to="/your-trials" className={classes.navLink}>
                            <Button variant="text" className={classes.navItem}>Your trials</Button>
                        </NavLink>
                    </RoleRestricted>

                    <RoleRestricted role={[RoleEnum.PARTICIPANT]}>
                        <NavLink exact activeClassName='is-active' to="/find-a-trial" className={classes.navLink}>
                            <Button variant="text" className={classes.navItem}>Find a trial</Button>
                        </NavLink>
                    </RoleRestricted>

                    { !isCompanySponsor &&
                        <RoleRestricted role={[RoleEnum.COMPANY, RoleEnum.ADMIN]}>
                            <PermissionRestricted type={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]}>
                                <NavLink exact activeClassName='is-active' to="/participants" className={classes.navLink}>
                                    <Button variant="text" className={classes.navItem}>Participants</Button>
                                </NavLink>
                            </PermissionRestricted>
                        </RoleRestricted>
                    }

                    <RoleRestricted role={[RoleEnum.PARTICIPANT]}>
                        <Box sx={{ flex: 1}} >
                            <Button href={"https://learn.evrima.com.au/help"} target={"_blank"} sx={{ alignSelf: 'flex-end'}} id={"faqs"} variant="outlined" color="primary" startIcon={<InfoOutlined />}>Read the FAQs</Button>
                        </Box>
                    </RoleRestricted>

                    {/*<RoleRestricted role={[RoleEnum.SITE, RoleEnum.RECRUITER, RoleEnum.ADMIN, RoleEnum.SPONSOR]}>*/}
                    {/*    <NavLink exact activeClassName='is-active' to="/dashboard" className={classes.navLink}>*/}
                    {/*        <Button variant="text" className={classes.navItem}>Dashboard</Button>*/}
                    {/*    </NavLink>*/}
                    {/*    /!*<NavMenu label={"Trials"} path={"trial"} />*!/*/}
                    {/*</RoleRestricted>*/}

                </Stack>



                <Stack direction="row" justifyContent={'flex-start'} alignItems={"center"} spacing={1}>
                    {/*<Box sx={{width:'200px'}}><Button id={"faqs"} variant="outlined" color="primary" startIcon={<InfoOutlined />}>Read the FAQs</Button></Box>*/}
                    <RoleRestricted role={[RoleEnum.COMPANY]}>
                        <NotificationPanelComponent />
                    </RoleRestricted>
                    <ProfileSection />
                </Stack>
            </Toolbar>

        </MuiAppBar>

    );
};

export default AppBar;
