/* eslint-disable no-unused-vars */
import * as actionTypes from '../../../store/actions';
import {
    REPORT_AWAITING_ACTIONS_LOAD,
    REPORT_AWAITING_ACTIONS_LOADED,
    REPORT_CONVERSION_RATE_LOAD,
    REPORT_CONVERSION_RATE_LOADED,
    REPORT_DISQUALIFICATION_REASONS_LOAD,
    REPORT_DISQUALIFICATION_REASONS_LOADED,
    REPORT_ENROLLMENT_TARGET_LOAD,
    REPORT_ENROLLMENT_TARGET_LOADED,
    REPORT_LOCATIONS_LOAD, REPORT_LOCATIONS_LOADED,
    REPORT_PARTICIPANT_JOURNEY_LOAD,
    REPORT_PARTICIPANT_JOURNEY_LOADED,
    REPORT_PERFORMANCE_LOAD,
    REPORT_PERFORMANCE_LOADED,
    REPORT_SITES_OVERVIEW_LOAD, REPORT_SITES_OVERVIEW_LOADED,
    REPORT_WITHDRAWN_REASONS_LOAD,
    REPORT_WITHDRAWN_REASONS_LOADED,
    REPORT_WORKFLOW_LOAD,
    REPORT_WORKFLOW_LOADED,
    REPORT_FILTER_LOAD,
    REPORT_FILTER_LOADED,
    REPORT_MARKETING_SOURCE_LOAD,
    REPORT_MARKETING_SOURCE_LOADED
} from './report.actions';
import produce from "immer";
import { REPORT_SINGLE_TRIAL_LOAD, REPORT_SINGLE_TRIAL_LOADED } from "./report.actions";
import { TaskStatusEnum } from "../../../shared/enums/taskStatusEnum";
import moment from "moment";
import { overallStatuses } from "../../../store/constant";
import {
    REPORT_TASK_BY_RANGE_FAILED,
    REPORT_TASK_BY_RANGE_LOAD,
    REPORT_TASK_BY_RANGE_LOADED
} from "./report.actions";
import { FrequencyTypeEnum } from "../widgets/taskByRange/frequencyTypeEnum";
import { ReportingService, TrialsService, UsersService } from "../../../api/connect";
import {
    REPORT_REFERRAL_TARGET_LOAD,
    REPORT_REFERRAL_TARGET_LOADED,
    REPORT_REGO_TARGET_LOAD, REPORT_REGO_TARGET_LOADED
} from "./report.actions";
import {MARKETING_SOURCES} from "../../../shared/constants";

const initialState = {
    trial: null,
    trialStartOfTime: null,
    trialName: null,
    participants: [],
    isLoadingTrials: true,
    isLoadingTrialData: true,
    taskFilters: [],
    isTaskFiltersLoading: false,
    siteFilters: [],
    trials: [],
    actions: [],
    isLoadingStatusByTask: true,
    statusByTaskData: [],
    isLoadingSitesOverview: true,
    sitesOverviewData: [],
    isLoadingTaskByRange: false,
    taskByRangeData: [],
    taskByRangeFailed: false,
    entities: [],
    isLoadingWorkflow: true,
    workflow: null,
    actionMeta: [
        {
            displayName: 'Passed',
            state: TaskStatusEnum.PASSED,
            color: '#41b987',
            priority: 0
        },
        {
            displayName: 'Done',
            state: TaskStatusEnum.DONE,
            color: '#41b987',
            priority: 0
        },
        {
            displayName: 'Referred',
            state: TaskStatusEnum.REFERRED,
            color: '#41b987',
            priority: 0
        },
        {
            displayName: 'Randomised',
            state: TaskStatusEnum.RANDOMISED,
            color: '#41b987',
            priority: 0
        },
        {
            displayName: 'Awaiting action',
            state: TaskStatusEnum.AWAITING_ACTION,
            color: '#0d4d78',
            priority: 1
        },
        {
            displayName: 'Attempted',
            state: TaskStatusEnum.ATTEMPTED,
            color: '#176DA7',
            priority: 1
        },
        {
            displayName: 'Pending review',
            state: TaskStatusEnum.PENDING_REVIEW,
            color: '#f28343',
            priority: 2
        },
        {
            displayName: 'Pending',
            state: TaskStatusEnum.PENDING,
            color: '#f28343',
            priority: 2
        },
        {
            displayName: 'Disqualified',
            state: TaskStatusEnum.DISQUALIFIED,
            color: '#c4426b',
            priority: 3
        },
        {
            displayName: 'Withdrawn',
            state: TaskStatusEnum.WITHDRAWN,
            color: '#ad2751',
            priority: 3
        },
        {
            displayName: 'Skipped',
            state: TaskStatusEnum.SKIPPED,
            color: '#898888',
            priority: 4
        },
    ],
    isLoadingDisqualificationReasons: true,
    disqualificationReasons: [],
    disqualificationOtherReasons: [],
    isLoadingWithdrawnReasons: true,
    withdrawnReasons: [],
    withdrawnOtherReasons: [],
    isLoadingAwaitingAction: true,
    awaitingActionData: {
        surveysPending: 0,
        phoneAppointmentsAwaitingAction: 0,
        phoneScreeningAttempted: 0
    },
    isLoadingEnrollmentTarget: true,
    enrollmentTargetData: {
        recruitmentTarget: 0,
        enrolledTotal: 0,
        leftToEnrol: 0,
        daysRemaining: 0
    },
    isLoadingReferredTarget: true,
    referralTargetData: {
        referralTarget: 0,
        referredTotal: 0,
        leftToRefer: 0,
        daysRemaining: 0
    },
    isLoadingRegoTarget: true,
    regoTargetData: {
        regoTarget: 0,
        regoTotal: 0,
        leftToRegister: 0,
        daysRemaining: 0
    },
    isLoadingPerformance: true,
    performanceData: {
        registrationsDone: 0,
        surveysPassed: 0,
        referralsReferred: 0,
        participantsRandomised: 0
    },
    isLoadingConversionRate: true,
    conversionRateData: {
        taskOne: {
            displayName: '',
            actionName: '',
            state: 0,
            total: 0
        },
        taskTwo: {
            displayName: '',
            actionName: '',
            state: 0,
            total: 0
        }
    },
    locations: [],
    marketingSourceData: [],
    isLoadingmarketingSourceData: true,
    participantCount: 0,
    sourceCount: 0,
    excludedTaskStates:[16,15]
};


const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REPORT_LOCATIONS_LOADED:
            return produce(state, draft => {
                draft.locations = action.data;
            });
        case actionTypes.REPORT_WORKFLOW_LOAD:
            return produce(state, draft => {
                draft.isLoadingWorkflow = true;
            });
        case actionTypes.REPORT_WORKFLOW_LOADED:
            return produce(state, draft => {

                // get actions
                const actions = [];
                action.data.stages.forEach((s, si) => {
                    s.tasks.forEach((t, i) => {
                        t.actions.forEach((a, i) => {
                            if (!actions.find((x) => x.state === a.taskState)) {

                                const actionMeta = draft.actionMeta.find(c => c.state === a.taskState && !draft.excludedTaskStates.includes(a.taskState));
                                if (actionMeta) {
                                    actions.push({
                                        displayName: a.displayName,
                                        state: a.taskState,
                                        priority: actionMeta.priority
                                    });
                                } else {
                                    console.error('Action not found!', { state: a.taskState, name: a.name});
                                }
                            }
                        });
                    })
                });

                // filter out excluded task states
                action.data.stages.forEach(stage => {
                    stage.tasks.forEach(task => {
                        task.actions = task.actions.filter(action => !draft.excludedTaskStates.includes(action.taskState));
                    });
                });

                draft.actions = actions.sort((a, b) => a.priority - b.priority);
                draft.workflow = action.data;
                draft.isLoadingWorkflow = false;

            });
        case actionTypes.REPORT_CONVERSION_RATE_LOAD:
            return produce(state, draft => {
                draft.isLoadingConversionRate = true;
            });
        case actionTypes.REPORT_CONVERSION_RATE_LOADED:
            return produce(state, draft => {
                draft.isLoadingConversionRate = false;
                if (action.data.length === 2 && draft.workflow && draft.workflow.stages) {

                    const taskOneStage = draft.workflow.stages.find(s => s.priority === action.data[0].stagePriority);
                    let taskOneActionState = 0;
                    if (!action.isTaskOneRegistration) {
                        for (let i = 0; i < taskOneStage.tasks.length; i++) {
                            for (let a = 0; a < taskOneStage.tasks[i].actions.length; a++) {
                                if (taskOneStage.tasks[i].actions[a].id === action.data[0].actionId) {
                                    taskOneActionState = taskOneStage.tasks[i].actions[a].taskState;
                                    break;
                                }
                            }
                        }
                    }

                    const taskTwoStage = draft.workflow.stages.find(s => s.priority === action.data[1].stagePriority);
                    let taskTwoActionState = 0;
                    if (!action.isTaskTwoRegistration) {
                        for (let i = 0; i < taskTwoStage.tasks.length; i++) {
                            for (let a = 0; a < taskTwoStage.tasks[i].actions.length; a++) {
                                if (taskTwoStage.tasks[i].actions[a].id === action.data[1].actionId) {
                                    taskTwoActionState = taskTwoStage.tasks[i].actions[a].taskState;
                                    break;
                                }
                            }
                        }
                    }

                    draft.conversionRateData = {
                        taskOne: {
                            displayName: `Stage ${action.data[0].stagePriority} - ${action.data[0].taskName}`,
                            actionName: action.data[0].actionName,
                            state: (action.isTaskOneRegistration) ? TaskStatusEnum.DONE : taskOneActionState,
                            total: action.data[0].count
                        },
                        taskTwo: {
                            displayName: `Stage ${action.data[1].stagePriority} - ${action.data[1].taskName}`,
                            actionName: action.data[1].actionName,
                            state: (action.isTaskTwoRegistration) ? TaskStatusEnum.DONE : taskTwoActionState,
                            total: action.data[1].count
                        }
                    }
                }
            });
        case actionTypes.REPORT_PERFORMANCE_LOAD:
            return produce(state, draft => {
                draft.isLoadingPerformance = true;
            });
        case actionTypes.REPORT_PERFORMANCE_LOADED:
            return produce(state, draft => {
                draft.isLoadingPerformance = false;
                draft.performanceData = {
                    registrationsDone: action.data[0].registrationsDone,
                    surveysPassed: action.data[0].surveysPassed,
                    referralsReferred: action.data[0].referralsReferred,
                    participantsRandomised: action.data[0].participantsRandomised
                }
            });

        case actionTypes.REPORT_ENROLLMENT_TARGET_LOAD:
            return produce(state, draft => {
                draft.isLoadingEnrollmentTarget = true;
            });
        case actionTypes.REPORT_ENROLLMENT_TARGET_LOADED:
            return produce(state, draft => {
                draft.isLoadingEnrollmentTarget = false;
                draft.enrollmentTargetData = {
                    enrolledTotal: action.data[0].enroledTotal,
                    leftToEnrol: action.data[0].leftToEnrol > 0 ? action.data[0].leftToEnrol : 0,
                    daysRemaining: action.data[0].daysRemaining > 0 ? action.data[0].daysRemaining : 0,
                    recruitmentTarget: action.data[0].recruitmentTarget
                }
            });
        case actionTypes.REPORT_REGO_TARGET_LOADED:
            return produce(state, draft => {
                draft.isLoadingRegoTarget = false;
                draft.regoTargetData = {
                    regoTotal: action.data[0].registeredTotal,
                    leftToRegister: action.data[0].leftToRegister > 0 ? action.data[0].leftToRegister : 0,
                    daysRemaining: action.data[0].daysRemaining > 0 ? action.data[0].daysRemaining : 0,
                    regoTarget: action.data[0].registrationTarget
                }
              });
        case actionTypes.REPORT_REFERRAL_TARGET_LOAD:
            return produce(state, draft => {
                draft.isLoadingReferredTarget = true;
            });
        case actionTypes.REPORT_REFERRAL_TARGET_LOADED:
            return produce(state, draft => {
                draft.isLoadingReferredTarget = false;
                draft.referralTargetData = {
                    referredTotal: action.data[0].referredTotal,
                    leftToRefer: action.data[0].leftToRefer > 0 ? action.data[0].leftToRefer : 0,
                    daysRemaining: action.data[0].daysRemaining > 0 ? action.data[0].daysRemaining : 0,
                    referralTarget: action.data[0].referralTarget
                }
            });
        case actionTypes.REPORT_AWAITING_ACTIONS_LOAD:
            return produce(state, draft => {
                draft.isLoadingAwaitingAction = true;
            });
        case actionTypes.REPORT_AWAITING_ACTIONS_LOADED:
            return produce(state, draft => {
                draft.isLoadingAwaitingAction = false;
                draft.awaitingActionData = {
                    surveysPending: action.data[0].surveysPending,
                    phoneAppointmentsAwaitingAction: action.data[0].phoneAppointmentsAwaitingAction,
                    phoneScreeningAttempted: action.data[0].phoneScreeningAttempted
                };
            });
        case actionTypes.REPORT_WITHDRAWN_REASONS_LOAD:
            return produce(state, draft => {
                draft.isLoadingWithdrawnReasons = true;
            });
        case actionTypes.REPORT_WITHDRAWN_REASONS_LOADED:
            return produce(state, draft => {
                draft.withdrawnReasons = action.data;
                draft.withdrawnOtherReasons = action.other;
                draft.isLoadingWithdrawnReasons = false;
            });

        case actionTypes.REPORT_DISQUALIFICATION_REASONS_LOAD:
            return produce(state, draft => {
                draft.isLoadingDisqualificationReasons = true;
            });
        case actionTypes.REPORT_DISQUALIFICATION_REASONS_LOADED:
            return produce(state, draft => {
                draft.disqualificationReasons = action.data;
                draft.disqualificationOtherReasons = action.other;
                draft.isLoadingDisqualificationReasons = false;
            });
        case actionTypes.REPORT_SINGLE_TRIAL_LOAD:
            return produce(state, draft => {
                draft.isLoadingTrialData = true;
            });
        case actionTypes.REPORT_SINGLE_TRIAL_LOADED:
            return produce(state, draft => {
                draft.isLoadingTrialData = false;
                draft.trial = action.trial;
                draft.trialStartOfTime = action.trial.createdDate;
            });
        case actionTypes.REPORT_PARTICIPANT_JOURNEY_LOAD:
            return produce(state, draft => {
                draft.isLoadingStatusByTask = true;
            });
        case actionTypes.REPORT_PARTICIPANT_JOURNEY_LOADED:
            return produce(state, draft => {

                if (draft.workflow && draft.workflow.stages) {

                    const tasks = [{
                        stageId: draft.workflow.stages[0].id,
                        stageDisplayName: 'Pre-screening',
                        stagePriority: 1,
                        taskId: 0,
                        taskDisplayName: 'Registration',
                        taskPriority: 1,
                        states: [{
                            actionId: 0,
                            state: TaskStatusEnum.DONE,
                            stateDisplayName: 'Done',
                            statePriority: 1,
                            total: 0
                        }]
                    }];

                    draft.workflow.stages.forEach(s => {
                        s.tasks.forEach(t => {
                            const task = {
                                stageId: s.id,
                                stageDisplayName: s.displayName,
                                stagePriority: s.priority,
                                taskId: t.id,
                                taskDisplayName: t.displayName,
                                taskPriority: t.priority,
                                states: []
                            }

                            t.actions.forEach(a => {
                                task.states.push({
                                    actionId: a.id,
                                    state: a.taskState,
                                    stateDisplayName: a.displayName,
                                    statePriority: a.priority,
                                    total: 0
                                })
                            });

                            tasks.push(task);
                        });
                    });



                    action.data.forEach(d => {
                        const stageId = (d.stageId === 0) ? draft.workflow.stages[0].id : d.stageId;
                        const task = tasks.find(t => t.stageId === stageId && t.taskId === d.taskId);
                        const state = task?.states.find(s => s.actionId === d.actionId);
                        if (state) {
                            state.total = d.count;
                        }
                    });

                    draft.statusByTaskData = action.data.length === 1 ? [] : tasks;
                    draft.isLoadingStatusByTask = false;
                }
            });
        case actionTypes.REPORT_SITES_OVERVIEW_LOAD:
            return produce(state, draft => {
                draft.isLoadingSitesOverview = true;
            });
        case actionTypes.REPORT_SITES_OVERVIEW_LOADED:
            return produce(state, draft => {
                draft.sitesOverviewData = action.data;
                draft.isLoadingSitesOverview = false;
            });
        case actionTypes.REPORT_SITES_LOAD:
            return produce(state, draft => {
                draft.isLoadingSites = true;
            });
        case actionTypes.REPORT_SITES_LOADED:
            return produce(state, draft => {
                draft.sites = (action.sites) ? [...action.sites.sort((a, b) => {
                    let fa = a.name.toLowerCase(),
                        fb = b.name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                })] : [];
                draft.isLoadingSites = false;
            });
        case actionTypes.REPORT_TASK_BY_RANGE_FAILED:
            return produce(state, draft => {
                draft.taskByRangeData = [];
                draft.isLoadingTaskByRange = false;
                draft.taskByRangeFailed = true;
            });
        case actionTypes.REPORT_TASK_BY_RANGE_LOAD:
            return produce(state, draft => {
                draft.taskByRangeData = [];
                draft.isLoadingTaskByRange = true;
                draft.taskByRangeFailed = false;
            });
        case actionTypes.REPORT_TASK_BY_RANGE_LOADED:
            return produce(state, draft => {

                let cumulative = 0;
                let data = [];
                let dates = action.data;

                dates.forEach((d, i) => {
                    const startDate = moment.utc(d.startDate);
                    const endDate = moment.utc(d.endDate);
                   // const sd = (startDate.local().isDST()) ? startDate.local().subtract(1, 'day') : startDate.local();
                   // const ed = (endDate.local().isDST()) ? endDate.local().subtract(1, 'day') : endDate.local();

                    const sd = startDate.local();
                    const ed = endDate.local();
                    let datesLabel = "";

                    switch (action.frequency) {
                        case FrequencyTypeEnum.WEEKLY:
                        case FrequencyTypeEnum.MONTHLY:
                        case FrequencyTypeEnum.YEARLY:
                            const std = sd.isDST() ? `${sd.format('ddd Do MMM YY')}` : `${sd.add(1, 'day').format('ddd Do MMM YY')}`;
                            const end = ed.isDST() ? `${ed.add(1, 'day').format('ddd Do MMM YY')}` : `${ed.format('ddd Do MMM YY')}`;
                            datesLabel = `${sd.format('Do MMM YY')} - ${ed.format('Do MMM YY')}`
                            break;
                        default:
                            datesLabel = ed.isDST() ? `${sd.format('ddd Do MMM YY')}` : `${ed.format('ddd Do MMM YY')}`
                    }

                    cumulative = cumulative + (d.count - d.undoCount);

                    data.push({ date: datesLabel, count: d.count, undoCount: (d.undoCount === 0) ? 0 : -d.undoCount, cumulative });
                });

                // fix for bug with responsive bar chart not showing last axis value
                if (action.frequency === FrequencyTypeEnum.DAILY) {
                    data.push({ date: '', count: 0, undoCount: 0, cumulative });
                }

                draft.taskByRangeData = data;
                draft.isLoadingTaskByRange = false;
            });
        case actionTypes.REPORT_SINGLE_TRIAL_UNLOAD:
            return produce(state, draft => {
                draft.trial = null;

                draft.participants = [];
                draft.isLoadingTrials = true;
                draft.isLoadingTrialData = false;
                draft.taskFilters = [];
                draft.siteFilters = [];
                draft.sites = [];
                draft.trials = [];
                draft.actions = [];
                draft.statusByTaskData = [];
                draft.sitesOverviewData = [];
                draft.entities = [];
                draft.workflow = [];
                draft.disqualificationReasons = [];
                draft.disqualificationOtherReasons = [];
                draft.withdrawnReasons = [];
                draft.withdrawnOtherReasons = [];
                draft.taskByRangeData = [];
                draft.taskByRangeFailed = false;
                draft.isLoadingTaskByRange = false;
                draft.awaitingActionData = {
                    surveysPending: 0,
                    phoneAppointmentsAwaitingAction: 0,
                    phoneScreeningAttempted: 0
                };

                draft.enrollmentTargetData = {
                    enrolledTarget: 0,
                    enrolledTotal: 0,
                    leftToEnrol: 0,
                    daysRemaining: 0
                };

                draft.referralTargetData = {
                    referralTarget: 0,
                    referredTotal: 0,
                    leftToRefer: 0,
                    daysRemaining: 0
                };

                draft.regoTargetData = {
                    regoTarget: 0,
                    regoTotal: 0,
                    leftToRegister: 0,
                    daysRemaining: 0
                };

                draft.performanceData = {
                    registrationsDone: 0,
                    surveysPassed: 0,
                    referralsReferred: 0,
                    participantsRandomised: 0
                };

                draft.conversionRateData = {
                    taskOne: {
                        displayName: '',
                        actionName: '',
                        state: 0,
                        total: 0
                    },
                    taskTwo: {
                        displayName: '',
                        actionName: '',
                        state: 0,
                        total: 0
                    }
                }
            });
        case actionTypes.REPORT_FILTER_LOAD:
            return produce(state, draft => {
                draft.taskFilters = [];
                draft.isTaskFiltersLoading = true;
            });
        case actionTypes.REPORT_FILTER_LOADED:
            return produce(state, draft => {
                draft.taskFilters = action.taskFilters;
                draft.isTaskFiltersLoading = false;
            });
        case actionTypes.REPORT_MARKETING_SOURCE_LOAD:
            return produce(state, draft => {
                draft.isLoadingmarketingSourceData = true;
            });
        case actionTypes.REPORT_MARKETING_SOURCE_LOADED:
            return produce(state, draft => {

                // {
                //     "source": "Doctor",
                //     "taskState": 0,
                //     "taskStateCount": 0,
                //     "notTaskStateCount": 0
                // },

                const sources = [];

                action.marketingSourceData.forEach((m) => {
                    const isHashed = m.source.split("#").length;
                    if (MARKETING_SOURCES.concat(['Google','Facebook','Instagram', 'Newspaper', 'Radio', 'Poster/Brochure', 'Television']).includes(m.source)) {
                        sources.push(m);
                    } else if(isHashed && ['Research Study Site', 'GP Practice', 'Advertising'].includes(m.source.split("#")[0])) {
                        const count = m.taskStateCount === 0 ? 1 : m.taskStateCount;
                        for(let i = 0; i < count; i++) {
                            sources.push({
                                "source": `${m.source.split("#")[0]}#${m.source.split("#")[1]}`,
                                "taskState": m.taskState,
                                "taskStateCount": m.taskStateCount === 0 ? 0 : 1,
                                "notTaskStateCount": m.notTaskStateCount
                            });
                        }
                    } else {
                        sources.push({
                            "source": `Other#${m.source}`,
                            "taskState": m.taskState,
                            "taskStateCount": m.taskStateCount,
                            "notTaskStateCount": m.notTaskStateCount
                        });
                    }
                })

                draft.marketingSourceData = sources;


                draft.sourceCount = action.sourceCount;
                draft.participantCount = action.participantCount;
                draft.isLoadingmarketingSourceData = false;
            });
        default:
            return state;
    }
};

export default reportReducer;


// Effects
export function getTrialById(trialId, userCognitoSub, callingUserCognitoSub) {
    return async (dispatch, getState) => {
        dispatch({ type: REPORT_SINGLE_TRIAL_LOAD });

                TrialsService.getApiTrialsGetTrial(trialId, userCognitoSub)
                    .then(trial => {
                        dispatch({
                            type: REPORT_SINGLE_TRIAL_LOADED,
                            trial: trial.results,
                        });
                    })
                    .catch(error => console.error(error));
    }
}

export function getLocations(callingUserCognitoSub) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_LOCATIONS_LOAD});
        return UsersService.getApiUsersGetPatientLocations().then(resp => {
            dispatch({type: REPORT_LOCATIONS_LOADED, data: resp.results});
        })
            .catch(error => console.error(error));
    }
}

export function getWorkflow(trialId, callingUserCognitoSub) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_WORKFLOW_LOAD});
        return TrialsService.getApiTrialsGetWorkflowByTrialId(trialId).then(resp => {
            dispatch({type: REPORT_WORKFLOW_LOADED, data: resp.results});
        })
            .catch(error => console.error(error));
    }
}


export function getDisqualificationReasons(trialId, from, to, callingUserCognitoSub, siteIds = null, stageId = null, stateName = null) {
    return async (dispatch, getState) => {
        dispatch({ type: REPORT_DISQUALIFICATION_REASONS_LOAD });

        return ReportingService.getApiReportingGetDqReasonsWidgetData(trialId, from, to, siteIds ? siteIds : "0", stateName, stageId)
            .then(reasons => {
                ReportingService.getApiReportingGetDqOtherReasonsWidgetData(trialId, from, to, siteIds ? siteIds : "0", stageId, stateName)
                    .then(other => {
                        dispatch({ type: REPORT_DISQUALIFICATION_REASONS_LOADED, data: reasons.results, other: other.results });
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }
}

export function getWithdrawnReasons(trialId, from, to, callingUserCognitoSub, siteIds = null, stageId = null, stateName = null) {
    return async (dispatch, getState) => {
        dispatch({ type: REPORT_WITHDRAWN_REASONS_LOAD });

        return ReportingService.getApiReportingGetWdReasonsWidgetData(trialId, from, to, siteIds ? siteIds : "0", stateName, stageId)
            .then(reasons => {
                ReportingService.getApiReportingGetWdOtherReasonsWidgetData(trialId, from, to, siteIds ? siteIds : "0", stageId, stateName)
                    .then(other => {
                        dispatch({ type: REPORT_WITHDRAWN_REASONS_LOADED, data: reasons.results, other: other.results });
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }
}

export function getAwaitingActions(trialId, callingUserCognitoSub, siteIds = null) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_AWAITING_ACTIONS_LOAD});
        return ReportingService.getApiReportingGetAwaitingActionWidgetData(trialId, siteIds ? siteIds : "0")
            .then(resp => {
                dispatch({ type: REPORT_AWAITING_ACTIONS_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}

export function getEnrollmentTarget(trialId, callingUserCognitoSub, siteIds, selectedSiteId) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_ENROLLMENT_TARGET_LOAD});
        return ReportingService.getApiReportingGetEnrolmentWidgetData(trialId, siteIds ? siteIds : "0", selectedSiteId ? selectedSiteId : "0")
            .then(resp => {
                dispatch({ type: REPORT_ENROLLMENT_TARGET_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}

export function getRegoTarget(trialId, callingUserCognitoSub, siteIds) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_REGO_TARGET_LOAD});
        return ReportingService.getApiReportingGetRegistrationWidgetData(trialId, siteIds ? siteIds : "0")
            .then(resp => {
                dispatch({ type: REPORT_REGO_TARGET_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}

export function getReferralTarget(trialId, callingUserCognitoSub, siteIds, selectedSiteId) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_REFERRAL_TARGET_LOAD});
        return ReportingService.getApiReportingGetReferralWidgetData(trialId, siteIds ? siteIds : "0", selectedSiteId ? selectedSiteId : "0")
            .then(resp => {
                dispatch({ type: REPORT_REFERRAL_TARGET_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}


export function getPerformance(trialId, from, to, callingUserCognitoSub, siteIds = null) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_PERFORMANCE_LOAD});
        return ReportingService.getApiReportingGetPerformanceWidgetData(trialId, from, to, siteIds ? siteIds : "0")
            .then(resp => {
                dispatch({ type: REPORT_PERFORMANCE_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}


export function getConversionRate(trialId, task1, task2, action1, action2, callingUserCognitoSub, siteIds = null) {
    return async (dispatch, getState) => {
        dispatch({type: REPORT_CONVERSION_RATE_LOAD});
        return ReportingService.getApiReportingGetConversionRateWidgetData(trialId, task1, action1, task2, action2, siteIds ? siteIds : "0")
            .then(resp => {
                dispatch({ type: REPORT_CONVERSION_RATE_LOADED, data: resp.results, isTaskOneRegistration: task1 === 0, isTaskTwoRegistration: task2 === 0 });
            })
            .catch(error => console.error(error));
    }
}


export function getParticipantJourney(trialId, from, to, callingUserCognitoSub, siteIds = null, stageId = null, stateName = null) {
    return async (dispatch, getState) => {
        dispatch({ type: REPORT_PARTICIPANT_JOURNEY_LOAD });

        return ReportingService.getApiReportingGetParticipantJourneyWidgetData(trialId, from, to ,siteIds ? siteIds : "0", stageId, stateName)
            .then(resp => {
                dispatch({ type: REPORT_PARTICIPANT_JOURNEY_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}


export function getSitesOverview(trialId, from, to, taskId, actionId, callingUserCognitoSub, siteIds = null) {
    return async (dispatch, getState) => {
        dispatch({ type: REPORT_SITES_OVERVIEW_LOAD });

        return ReportingService.getApiReportingGetSiteOverviewWidgetData(trialId, from, to, taskId, actionId ,siteIds ? siteIds : "0")
            .then(resp => {
                dispatch({ type: REPORT_SITES_OVERVIEW_LOADED, data: resp.results });
            })
            .catch(error => console.error(error));
    }
}

export function getTaskByRange(trialId, from, to, taskId, actionId, frequency, callingUserCognitoSub, siteIds = null, stateName = null) {
    return async (dispatch, getState) => {
        dispatch({ type: REPORT_TASK_BY_RANGE_LOAD });

        return ReportingService.getApiReportingGetTaskByDateRangeWidgetData(trialId, from, to, taskId, actionId, frequency,siteIds ? siteIds : "0", stateName)
            .then(resp => {

                const data = resp.results.map(d => ({
                    startDate: d.startDate,
                    endDate: d.endDate,
                    count: d.count,
                    undoCount: d.undoCount
                }));

                dispatch({ type: REPORT_TASK_BY_RANGE_LOADED, data, frequency });
            })
            .catch(error => {
                dispatch({ type: REPORT_TASK_BY_RANGE_FAILED });
                console.error(error);
            });

    }
}



function getActionMeta(workflow, actionId) {
    for (let s = 0; s < workflow.stages.length; s++) {
        for (let t = 0; t < workflow.stages[s].tasks.length; t++) {
            for (let a = 0; a < workflow.stages[s].tasks[t].actions.length; a++) {
                if (workflow.stages[s].tasks[t].actions[a].id === actionId) {
                    return workflow.stages[s].tasks[t].actions[a];
                }
            }
        }
    }
    return null;
}


function makeRawData(participants) {

    const entities = [];

    participants.forEach((p) => {
        const overallStatus = overallStatuses.find(o => o.index === p.patientWorkflow.overallStatus);
        const entity = {};
        entity.ID = p.patientId;
        entity.FIRSTNAME = p.firstName;
        entity.LASTNAME = p.lastName;
        entity.DOB = p.dob;
        entity.AGE = moment().diff(p.dob, 'years');
        entity.LOCATION = p.location;
        entity.STATE = p.state;
        entity.COUNTRY = p.country;
        entity.OVERALL_STATUS = `${p.patientWorkflow.stageLabel}/${overallStatus.label}`;

        let priorityCount = 1;
        p.patientWorkflow.stages.forEach((stage, si) => {
            stage.tasks.forEach((task, i) => {
                const stageNo = si + 1;
                const taskName = task.displayName.replace(new RegExp(" ", "g"), "_").toUpperCase();
                entity[`STAGE_${stageNo}_${taskName}_LABEL`] = task.displayName;
                entity[`STAGE_${stageNo}_${taskName}_STATUS`] = task.currentState;
                entity[`STAGE_${stageNo}_${taskName}_DATE`] = moment.utc(task.actionDate).local().format();
                entity[`STAGE_${stageNo}_${taskName}_PRIORITY`] = priorityCount;
                priorityCount++;

                entity[`STAGE_${stageNo}_${taskName}_META_DATA`] = (task.messageForDisplay) ? task.messageForDisplay
                    .replace('Disqualified: ', '')
                    .replace('Withdrawn: ', '')
                    .replace('Referred to site ', '')
                    .replace('Referred to ', '')
                    .replace(/\.$/, '')
                    .replace('|', ' ') : '';
            })
        })

        entities.push(entity);
    });

    return entities;
}

export function getFilterByTrialId(trialId, userCognitoSub, stage) {
    return async (dispatch, getState) => {

        dispatch({ type: REPORT_FILTER_LOAD });

        return TrialsService.postApiTrialsGetTaskCountFiltersByTrial(userCognitoSub,{
                    trialId: Number(trialId),
                    siteId: null,
                    stagePriority: stage === "stage1" ? 1 : 2,
                    showRegistrations: true,
                })
                    .then(filters => {
                        dispatch({
                            type: REPORT_FILTER_LOADED,
                            taskFilters: filters.results || []
                        });
            })
            .catch(error => console.error(error));
    }
}

export function getSourceWidgetData(callingUserCognitoSub, trialId, stagePriority, taskId, taskState, startDate, endDate, sourceTypes) {
    return async (dispatch, getState) => {

        dispatch({ type: REPORT_MARKETING_SOURCE_LOAD });

        return ReportingService.getApiReportingGetSourceWidgetData(
            callingUserCognitoSub, 
            trialId, 
            stagePriority, 
            taskId, 
            taskState, 
            startDate, 
            endDate,
            sourceTypes
        ).then(resp => {
            dispatch({
                type: REPORT_MARKETING_SOURCE_LOADED,
                marketingSourceData: resp.marketingSources || [],
                sourceCount: resp.sourceCount,
                participantCount: resp.participantCount
            });
        })
        .catch(error => console.error(error));
    }
}



