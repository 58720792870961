import * as actionTypes from '../../../../store/actions';
import {TaskStatusEnum} from "../../../../shared/enums/taskStatusEnum";
import produce from "immer";
import {
    PARTICIPANT_COMMENT_DELETE, PARTICIPANT_COMMENT_DELETED,
    PARTICIPANT_COMMENT_SAVE, PARTICIPANT_COMMENT_SAVED,
    PARTICIPANT_COMMENTS_LOAD, PARTICIPANT_COMMENTS_LOADED,
    PARTICIPANT_DETAILS_HTML_EMAIL_LOAD,
    PARTICIPANT_DETAILS_HTML_EMAIL_LOADED,
    PARTICIPANT_DETAILS_LOAD,
    PARTICIPANT_DETAILS_LOADED,
    PARTICIPANT_DETAILS_RELOAD_ACTIVITY,
    PARTICIPANT_DETAILS_RELOADED,
    PARTICIPANT_DETAILS_SURVEY_LOAD,
    PARTICIPANT_DETAILS_SURVEY_LOADED,
    PARTICIPANT_DETAILS_TASK_STATUS_CHANGE,
    PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVE,
    PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVED,
    PARTICIPANT_FILE_REQUESTS_LOAD,
    PARTICIPANT_FILE_REQUESTS_LOADED,
    PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVE,
    PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVED,
    PARTICIPANT_FILES_DELETE,
    PARTICIPANT_FILES_DELETED,
    PARTICIPANT_FILES_LOAD,
    PARTICIPANT_FILES_LOADED,
    PARTICIPANT_FILES_UPLOAD,
    PARTICIPANT_FILES_UPLOAD_DIALOG_CLOSED,
    PARTICIPANT_FILES_UPLOAD_FAILED,
    PARTICIPANT_FILES_UPLOADED
} from "../../../../store/actions";
import {getParticipants, getTrialById} from "../../../single-trial/state/single-trial.reducer";
import {
    CommentsService,
    JotformService,
    MediaService,
    NotificationsService,
    TrialsService,
    UsersService
} from "../../../../api/connect";
import {SurveyModel} from "../models/survey.model";

import moment from "moment";
import {ParticipantFilesListModel} from "../models/participant-files-list.model";
import {FileStatusEnum} from "../../../../shared/enums/file-status.enum";
import {FileRejectionReasonEnum} from "../../../../shared/enums/file-rejection-reason.enum";
import {RequestModel} from "../../../file-library/models/request.model";
import {FileModel} from "../../../file-library/models/file.model";
import {CommentModel} from "../models/comment.model";
import {OrderByEnum} from "../../../../shared/enums/order-by.enum";



const initialState = {
    isLoading: true,
    isActionLoading: false,
    isPreviewLoading: false,
    selectedParticipant: null,
    htmlPreview: '',
    submissions: [],
    submission : null,
    isLoadingSubmission: false,
    isLoadingFiles: false,
    files: [],
    isUploading: false,
    closeDialog: false,
    uploadError: false,
    selectedRequest: null,
    isLoadingRequest: false,
    isLoadingRequests: false,
    requests: [],
    savingImageId: null,
    savingRequestId: null,
    comments: [],
    isSavingComment: false,
    isLoadingComments: false
};

const participantDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PARTICIPANT_COMMENT_SAVE:
            return produce(state, draft => {
                draft.isSavingComment = true;
            });
        case actionTypes.PARTICIPANT_COMMENT_SAVED:
            return produce(state, draft => {
                draft.isSavingComment = false;
            });
        case actionTypes.PARTICIPANT_COMMENTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingComments = true;
            });
        case actionTypes.PARTICIPANT_COMMENTS_LOADED:
            return produce(state, draft => {
                draft.comments = action.comments.map(c => new CommentModel(
                    c.id,
                    c.description,
                    c.authorCognitoSub,
                    c.authorFirstName,
                    c.authorLastName,
                    c.companyName,
                    moment.utc(c.createdDateTime).local().format())
                );
                draft.isLoadingComments = false;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVE:
            return produce(state, draft => {
                draft.savingRequestId = action.savingRequestId;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVED:
            return produce(state, draft => {
                draft.savingRequestId = null;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVE:
            return produce(state, draft => {
                draft.savingImageId = action.savingImageId;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVED:
            return produce(state, draft => {
                draft.savingImageId = null;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_REQUEST_LOAD:
            return produce(state, draft => {
                draft.isLoadingRequest = true;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_REQUEST_LOADED:
            return produce(state, draft => {
                const request = action.request;
                if (request) {
                    draft.selectedRequest = new RequestModel(
                        request.requestId,
                        request.description,
                        request.requestFileStatus.length ? request.requestFileStatus[0].patientCognitoSub : '',
                        request.requestFileStatus.length ? request.requestFileStatus[0].patientFirstName : '',
                        request.requestFileStatus.length ? request.requestFileStatus[0].patientLastName : '',
                        request.requestFileStatus,
                        request.trialImages ? request.trialImages
                            .map((file) => new FileModel(file.id,
                                file.imageKey,
                                file.description,
                                moment.utc(file.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                                request.requestId,
                                file.authorCognitoSub,
                                file.imageStatuses)
                            ) : []
                    )
                }
                draft.isLoadingRequest = false;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_REQUEST_UNLOAD:
            return produce(state, draft => {
                draft.selectedRequest = null;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingRequests = true;
            });
        case actionTypes.PARTICIPANT_FILE_REQUESTS_LOADED:
            return produce(state, draft => {

                const requests = [];

                action.requests.forEach((r) => {
                    requests.push(new RequestModel(
                        r.requestId,
                        r.description,
                        r.requestFileStatus.length ? r.requestFileStatus[0].patientCognitoSub : '',
                        r.requestFileStatus.length ? r.requestFileStatus[0].patientFirstName : '',
                        r.requestFileStatus.length ? r.requestFileStatus[0].patientLastName : '',
                        r.requestFileStatus,
                        r.trialImages ?  r.trialImages
                            .map((file) => new FileModel(file.id,
                                file.imageKey,
                                file.description,
                                moment.utc(file.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                                r.requestId,
                                file.authorCognitoSub,
                                file.imageStatuses)
                            ) : []
                    ))
                });

                // action.files.forEach((f: any) => {
                //     files.push(new FileLibraryListModel(
                //         f.id,
                //         f.imageKey,
                //         f.description,
                //         moment.utc(f.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                //         f.imageStatuses,
                //         f.patientFirstName,
                //         f.patientLastName,
                //         f.requestId,
                //         f.patientCognitoSub
                //     ))
                // });
                //
                draft.requests = requests;
                // draft.files = files;
                draft.isLoadingRequests = false;
            });
        case actionTypes.PARTICIPANT_DETAILS_SURVEY_LOAD:
            return produce(state, draft => {
                draft.isLoadingSubmission = true;
            });
        case actionTypes.PARTICIPANT_DETAILS_SURVEY_LOADED:
            return produce(state, draft => {
                draft.submission = action.submission;
                draft.isLoadingSubmission = false;
            });
        case actionTypes.PARTICIPANT_DETAILS_HTML_EMAIL_LOAD:
            return produce(state, draft => {
                draft.isPreviewLoading = true;
            });
        case actionTypes.PARTICIPANT_DETAILS_HTML_EMAIL_LOADED:
            return produce(state, draft => {
                draft.htmlPreview = action.htmlEmail.template;
                draft.isPreviewLoading = false;
            });
        case actionTypes.PARTICIPANT_DETAILS_TASK_STATUS_CHANGE:
            return produce(state, draft => {
                draft.isActionLoading = true;
            });
        case actionTypes.PARTICIPANT_DETAILS_TOGGLE_PROGRESS_STAGE:
            return produce(state, draft => {
                draft.selectedParticipant.stages.forEach((stage, i) => {
                    if (stage.id === action.stageId) {
                        stage.expanded = action.expand;
                    }
                })
            });
        case actionTypes.PARTICIPANT_DETAILS_CLOSE:
            return produce(state, draft => {
                draft.selectedParticipant = null;
            });
        case actionTypes.PARTICIPANT_DETAILS_OPEN:
            return produce(state, draft => {

            });
        case actionTypes.PARTICIPANT_DETAILS_LOAD:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        case actionTypes.PARTICIPANT_DETAILS_RELOAD_ACTIVITY:
            return produce(state, draft => {
                draft.selectedParticipant.activity = action.activity;
            });
        case actionTypes.PARTICIPANT_DETAILS_LOADED:
        case actionTypes.PARTICIPANT_DETAILS_RELOADED:
            return produce(state, draft => {
                const participant = action.participant;


                // sort tasks and stages by priority
                action.workflow.stages
                    .sort((a, b) => a.priority - b.priority)
                    .forEach(stage => {
                        stage.tasks.sort((a, b) => a.priority - b.priority);
                });


                participant.stages = action.workflow.stages;
                participant.workflowId = action.workflow.id;
                participant.submissions = action.submissions ? action.submissions : [];
                participant.overallStatus = action.workflow.overallStatus;
                participant.stageLabel = action.workflow.stageLabel;
                participant.activity = action.activity;
                participant.preferredTimes = Array.isArray(action.preferredTimes) ? action.preferredTimes : [];

                draft.submissions = action.submissions && action.submissions.length ? action.submissions.map((s) => new SurveyModel(s.task?.displayName, s.task?.id, s.submissionId, s.submissionReceivedDate)) : [];


                participant.stages = produce(participant.stages, draft_stages => {
                    draft_stages.forEach((stage, si) => {

                        // check if user can edit stage
                        let rolesThatCanAction = [];
                        stage.tasks.forEach((task, ti) => {
                            task.rolesCanAction.forEach((r,i) => {
                                rolesThatCanAction.push(r);
                            });
                        });

                        stage.canEdit = [...new Set(rolesThatCanAction)];
                        stage.activeStepIndex = -1;

                        if ([TaskStatusEnum.REFERRED,TaskStatusEnum.RANDOMISED,TaskStatusEnum.WITHDRAWN,TaskStatusEnum.DISQUALIFIED].includes(stage.tasks[(stage.tasks.length-1)].currentState)){
                            stage.activeStepIndex = stage.tasks.length;
                        } else {
                            for (let i = (stage.tasks.length - 1); i >= 0; i--) {
                                if (stage.tasks[i].currentState === TaskStatusEnum.AWAITING_ACTION ||
                                    stage.tasks[i].currentState === TaskStatusEnum.IN_PROGRESS ||
                                    stage.tasks[i].currentState === TaskStatusEnum.ATTEMPTED ||
                                    stage.tasks[i].currentState === TaskStatusEnum.REFERRED ||
                                    stage.tasks[i].currentState === TaskStatusEnum.RANDOMISED ||
                                    stage.tasks[i].currentState === TaskStatusEnum.WITHDRAWN ||
                                    stage.tasks[i].currentState === TaskStatusEnum.PENDING_REVIEW ||
                                    stage.tasks[i].currentState === TaskStatusEnum.PENDING ||
                                    stage.tasks[i].currentState === TaskStatusEnum.DISQUALIFIED) {
                                    stage.activeStepIndex = i;
                                    break;
                                }
                            }
                        }

                        stage.expanded = (stage.activeStepIndex !== stage.tasks.length);
                    });
                })

                draft.selectedParticipant = participant;
                draft.isLoading = false;
                draft.isActionLoading = false;
            });
        case PARTICIPANT_FILES_LOAD:
            return produce(state, (draft) => {
                draft.isLoadingFiles = true;
            });
        case PARTICIPANT_FILES_LOADED:
            return produce(state, (draft) => {
                draft.files = action.files.filter(a => !a.requestId)
                    .map(a => new ParticipantFilesListModel(
                        a.id,
                        a.imageKey,
                        a.description,
                        moment.utc(a.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                        a.patientCognitoSub,
                        a.imageStatuses));
                draft.isLoadingFiles = false;
            });
        case PARTICIPANT_FILES_UPLOAD:
            return produce(state, (draft) => {
                draft.isUploading = true;
                draft.uploadError = false;
            });
        case PARTICIPANT_FILES_UPLOADED:
            return produce(state, (draft) => {
                draft.isUploading = false;
                draft.closeDialog = true;
                draft.uploadError = false;
            });
        case PARTICIPANT_FILES_UPLOAD_DIALOG_CLOSED:
            return produce(state, (draft) => {
                draft.closeDialog = false;
                draft.uploadError = false;
            });
        case PARTICIPANT_FILES_UPLOAD_FAILED:
            return produce(state, (draft) => {
                draft.isUploading = false;
                draft.uploadError = true;
            });
        case PARTICIPANT_FILES_DELETE:
            return produce(state, (draft) => {
               const file = draft.files.find((f) => f.id === action.isDeleting);
               if (file){
                  file.isDeleting = true;
               }
            });
        case PARTICIPANT_FILES_DELETED:
            return produce(state, (draft) => {
                draft.files = [...draft.files].filter((f) => f.id !== action.isDeleting);
            });
        default:
            return state;
    }
};

export default participantDetailsReducer;


// Effects

export function getParticipantById(participantId, trialId, userRole, callingUserCognitoSub) {
    return async (dispatch, getState) => {
        dispatch({type: PARTICIPANT_DETAILS_LOAD });
        return UsersService.getApiUsersGetPatientBySub(participantId)
            .then(participant => {
                        JotformService.getApiJotformGetSubmissionByPatientId(participant.results.id, trialId)
                            .then(resp => {
                                const submissions = resp.results ? resp.results : [];
                                        TrialsService.getApiTrialsGetPatientEventHistory(participantId, trialId)
                                            .then(activity => {
                                                UsersService.getApiUsersGetPreferredTimesPreference(participantId)
                                                    .then(preferredTimes => {
                                                     const activeWorkflow = participant.results?.uiWorkflows.find(t => t.trialId === trialId);
                                                    dispatch({type: PARTICIPANT_DETAILS_LOADED,
                                                        participant: participant.results,
                                                        activity: activity.results.filter(a => a.trialId === trialId),
                                                        workflow: activeWorkflow,
                                                        submissions,
                                                        userRole,
                                                        preferredTimes: preferredTimes.results});
                                                })
                                                .catch(error => console.error(error));
                                            })
                                            .catch(error => console.error(error));

                            })
                            .catch(error => console.error(error));
                    })
            .catch(error => console.error(error));
    }
}

export function reloadActivity(participantId, trialId, callingUserCognitoSub){
    return async (dispatch, getState) => {
        return TrialsService.getApiTrialsGetPatientEventHistory(participantId, trialId)
            .then(activity => {
                dispatch({type: PARTICIPANT_DETAILS_RELOAD_ACTIVITY,  activity: activity.results.filter(a => a.trialId === trialId)});
            })
            .catch(error => console.error(error));
    }
}

export function getHtmlEmailTemplate(notificationType, notes, trialId, siteId, callingUserCognitoSub, participantCognitoSub){
    return async (dispatch, getState) => {
        dispatch({type: PARTICIPANT_DETAILS_HTML_EMAIL_LOAD});
        return NotificationsService.getApiNotificationsGetPreview(participantCognitoSub, notificationType, notes, trialId, siteId)
        .then(htmlEmail => {
            dispatch({type: PARTICIPANT_DETAILS_HTML_EMAIL_LOADED, htmlEmail});
        })
        .catch(error => console.error(error));
    }
}


export function getSurveySubmission(submissionId, callingUserCognitoSub){
    return async (dispatch, getState) => {
        dispatch({type: PARTICIPANT_DETAILS_SURVEY_LOAD});
        return JotformService.getApiJotformGetSubmissionById(submissionId)
            .then(sub => {
                dispatch({type: PARTICIPANT_DETAILS_SURVEY_LOADED, submission: sub.surveyAnswers});
            })
            .catch(error => console.error(error));
    }
}

export function postWorkflowEvent(participantId, userId, siteId, trialId, workflowId, stageId, taskId, actionId, eventMeta, eventMetaType, userRole, other, notes){
    return async (dispatch, getState) => {
        dispatch({type: PARTICIPANT_DETAILS_TASK_STATUS_CHANGE });
        return TrialsService.postApiTrialsAddWorkflowEvent({
            userCognitoSub: participantId,
            authorCognitoSub: userId,
            trialId,
            workflowId,
            stageId,
            taskId,
            actionId,
            eventMeta,
            eventMetaType,
            freeText: (other) ? other : '',
            notificationNotes: (notes) ? notes : '',
        })
            .then(workflowEvent => {
                UsersService.getApiUsersGetPatientBySub(participantId)
                    .then(participant => {
                        JotformService.getApiJotformGetSubmissionByPatientId(participant.results.id, trialId)
                            .then(subs => {
                                const submissions = subs.results ? subs.results : [];
                                    TrialsService.getApiTrialsGetPatientEventHistory(participantId, trialId)
                                        .then(activity => {
                                            UsersService.getApiUsersGetPreferredTimesPreference(participantId)
                                                .then(preferredTimes => {
                                                const activeWorkflow = participant.results?.uiWorkflows.find(t => t.trialId === trialId);
                                                dispatch({
                                                    type: PARTICIPANT_DETAILS_RELOADED,
                                                    participant: participant.results,
                                                    activity: activity.results.filter(a => a.trialId === trialId),
                                                    workflow: activeWorkflow,
                                                    userRole,
                                                    preferredTimes: preferredTimes.results,
                                                    submissions
                                                });
                                                setTimeout(() => { dispatch(getParticipants(userId))}, 2000);
                                            })
                                            .catch(error => console.error(error));
                                        })
                                        .catch(error => console.error(error));
                            })
                            .catch(error => console.error(error));
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }
}

export function deleteFileById(imageId, userCognitoSub, authCognitoSub, trialId) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_FILES_DELETE, isDeleting: imageId })
        try {
            MediaService.deleteApiMediaDeletePatientFile({
                imageId: Number(imageId),
                trialId: trialId,
                userCognitoSub: userCognitoSub,
                authorCognitoSub: authCognitoSub,
                status: FileStatusEnum.Rejected,
                rejectReason: FileRejectionReasonEnum.Other,
                rejectionReason: 'Deleted'
            })
                .then((resp) => {
                    dispatch({type: PARTICIPANT_FILES_DELETED, isDeleting: imageId})
                })
                .catch(err => console.log(err))
        } catch (err) {
            console.error(err);
        }
    }
}

export function getFiles(userCognitoSub,
                         trialId,
                         callingUserCognitoSub,
                         resultsPerPage = 10,
                         pageNumber = 1,
                         sortBy = null,
                         orderBy = null,
                         search = null) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_FILES_LOAD})
        try {
            MediaService.postApiMediaGetPatientFilesByTrialId({
                trialId,
                userCognitoSub,
                resultsPerPage,
                pageNumber,
                sortBy,
                orderBy,
                search})
                .then((resp) => {
                    dispatch({type: PARTICIPANT_FILES_LOADED, files: resp.results || []})
                })
                .catch(err => console.log(err))
        } catch (err) {
            console.error(err);
        }
    }
}

export function uploadFiles(userCognitoSub, trialId,callingUserCognitoSub, files = [], requestId, originalImageId) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_FILES_UPLOAD});

        let promises = [];

        files.forEach((file, index) => {
            promises.push(MediaService.postApiMediaUploadFile({
                filedata: file.raw,
                userCognitoSub: userCognitoSub,
                trialId: trialId.toString(),
                requestId: requestId !== undefined ? requestId.toString() : undefined,
                description: file.description,
                originalImageId: originalImageId !== undefined ? originalImageId : undefined
            }));
        });

        Promise
            .all(promises)
            .then((response) => {
                dispatch({type: PARTICIPANT_FILES_UPLOADED});
                dispatch(getFiles(userCognitoSub, trialId, userCognitoSub));
            }).catch((err) => {
            dispatch({type: PARTICIPANT_FILES_UPLOAD_FAILED});
        });
    }
}


export function getRequestsByTrialAndUser(trialId,
                                          participantCognitoSub,
                                          callingUserCognitoSub,
                                          pageNumber = 1,
                                          resultsPerPage = 10,
                                          orderBy =  null,
                                          search =  null,
                                          status = null,
                                          sortBy =  null,
                                          ) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_FILE_REQUESTS_LOAD});

        MediaService.getApiMediaGetRequestFileTypeByTrialId(trialId)
            .then(requests => {

                const promises = [];

                requests.results.forEach(r => {
                    promises.push(MediaService.postApiMediaGetPatientRequestFilesByTrialId( {
                        trialId: Number(trialId),
                        resultsPerPage,
                        pageNumber,
                        userCognitoSub: participantCognitoSub,
                        requestId: r.requestId,
                        sortBy,
                        orderBy,
                        search,
                        status
                    }));
                });

                Promise.all(promises)
                    .then(fileRequests => {
                        dispatch({
                            type: PARTICIPANT_FILE_REQUESTS_LOADED,
                            requests: fileRequests.map(r => r.results.pop())
                        });
                    })
            })
            .catch(error => console.error(error));
    }
}


export function saveFileStatusChanges(trialId, imageId, participantCognitoSub, authorCognitoSub, status, rejectReason, otherRejectionReason) {
    return async (dispatch) => {
        dispatch({type:PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVE, savingImageId: imageId});
        MediaService.putApiMediaUpdatePatientFileStatus({
            imageId: Number(imageId),
            trialId: Number(trialId),
            userCognitoSub: participantCognitoSub,
            authorCognitoSub: authorCognitoSub,
            status: status,
            rejectReason: rejectReason,
            rejectionReason: otherRejectionReason
        }).then(results => {
            dispatch({type:PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVED});
        });
    }
}

export function saveRequestStatusChanges(requestId, participantCognitoSub, authorCognitoSub, status, reason) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVE, savingRequestId: requestId});
        MediaService.putApiMediaUpdatePatientRequestFileStatus({
            requestId: Number(requestId),
            userCognitoSub: participantCognitoSub,
            authorCognitoSub: authorCognitoSub,
            requestFileStatus: status,
            rejectionReason: reason || undefined
        }).then(results => {
            dispatch({type: PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVED});
        });
    }
}

export function deleteComment(trialId, participantCognitoSub, commentId, callingUserCognitoSub) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_COMMENT_DELETE});

        CommentsService.putApiCommentsRemoveComment(commentId)
            .then(_ => {
                dispatch({type: PARTICIPANT_COMMENT_DELETED, commentId});
                dispatch(loadComments(trialId, participantCognitoSub, callingUserCognitoSub));
            });
    }
}

export function saveComment(trialId, participantCognitoSub, comment, authorCognitoSub) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_COMMENT_SAVE});

        CommentsService.postApiCommentsCreateComment({
            trialId,
            comment,
            userCognitoSub: participantCognitoSub,
            authorCognitoSub
        }).then(_ => {
            dispatch({type: PARTICIPANT_COMMENT_SAVED});
            dispatch(loadComments(trialId, participantCognitoSub,authorCognitoSub));
        });
    }
}

export function loadComments(trialId, 
                            participantCognitoSub, 
                            authorCognitoSub,
                            resultsPerPage = 100,
                             pageNumber = 1,
                             sortBy = OrderByEnum.DESC,
                             orderBy = 'created',
                             search = null) {
    return async (dispatch) => {
        dispatch({type: PARTICIPANT_COMMENTS_LOAD});
        CommentsService.postApiCommentsGetComments({
            resultsPerPage,
            pageNumber,
            sortBy,
            orderBy,
            search,
            trialId,
            userCognitoSub: participantCognitoSub,
            authorCognitoSub
        }).then(comm => {
            dispatch({type: PARTICIPANT_COMMENTS_LOADED, comments: comm.results});
        });
    }
}