import { createTheme } from '@material-ui/core/styles';

// assets

import evrima from '../assets/scss/_evrima.module.scss';

// project imports
import { componentStyleOverrides } from './compStyleOverride';
import { themePalette } from './palette';
import { themeTypography } from './typography';
import { customShadows } from './shadows';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export function theme(customization) {
    let color = evrima; 

    let themeOption = {
        colors: color,
        heading: color.black,
        paper: color.paper,
        backgroundDefault:color.background,
        background: color.background,
        darkTextPrimary: color.black,
        darkTextSecondary: color.black,
        textDark: color.black,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
        borderRadius : '12px',
        customization: customization
    };



    return createTheme({
        direction: customization.rtlLayout ? 'rtl' : 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        customShadows: customShadows(customization.navType, themeOption),
        typography: themeTypography(themeOption),
        components: componentStyleOverrides(themeOption)
    });
}

export default theme;
