

/**
 * MUI Components whose styles are override as per theme
 * @param {JsonObject} theme Plain Json Object
 */
export function componentStyleOverrides(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: theme.background,
                    "&::-webkit-scrollbar": {
                        width: "9px",
                        height: "9px"
                    },
                    "&::-webkit-scrollbar-track": {
                            background: "0 0",
                            webkitBoxShadow: "none",
                            boxShadow: "none",
                            borderRadius: "5px"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "rgba(59,59,59,.2)",
                        borderRadius: "5px",
                        webkitTransition: "all .3s ease",
                        transition: "all .3s ease"
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'none',
                    borderRadius: '10rem'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: theme.background,
                    borderTopLeftRadius: '1rem',
                    borderBottomLeftRadius: '1rem'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {

                },
                rounded: {
                    borderRadius: theme.customization.borderRadius + 'px'
                }
            }
        },
        MuiCard: {
          styleOverrides: {
              root: {
                  boxShadow: '0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1)'
              }
          }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                elevation: 3
            },
            styleOverrides: {
                root: {
                    "& .MuiMenuItem-root": {
                        padding: '.7rem 1rem',
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                        color: theme.colors.primaryMain,
                        background: theme.colors.primaryLight
                    },
                    "& .MuiMenuItem-root:hover": {
                        background: '#eee'
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                        background: '#eee'
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiListItem-root': {
                        color: theme.darkTextPrimary,
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        backgroundColor: 'transparent',
                        '&.Mui-selected': {
                            color: theme.menuSelected,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            '& .MuiListItemIcon-root': {
                                color: theme.colors.primaryMain
                            }
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.menuSelected,
                            '& .MuiListItemIcon-root': {
                                color: theme.colors.primaryMain
                            }
                        },
                        '& .MuiTouchRipple-child': {
                            backgroundColor: theme.colors.grey500
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.colors.black,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& > label:not(.MuiFormLabel-filled):not(.Mui-disabled):not(.MuiInputLabel-shrink)': {
                        color: '#8d8787'
                    }

                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme.customization.outlinedFilled
                        ? theme.customization.navType === 'dark'
                            ? theme.colors.darkBackground
                            : theme.colors.grey50
                        : 'transparent',
                    borderRadius: theme.customization.borderRadius + 'px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 28 : theme.colors.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: theme.customization.outlinedFilled
                        ? theme.customization.navType === 'dark'
                            ? theme.colors.darkBackground
                            : theme.colors.grey50
                        : 'transparent',
                    padding: '15.5px 14px',
                    borderRadius: theme.customization.borderRadius + 'px',
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: theme.customization.borderRadius + 'px'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 50 : theme.colors.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme.colors.primaryMain
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background: '#fff',
                        borderRadius: '.3rem',
                        color: theme.textDark,
                        height: '2rem',
                        width: 'auto',
                        '.MuiChip-avatar':{
                            marginLeft: '.1rem'
                        },
                        '.MuiChip-deleteIcon': {
                            color: "#7d7d7d !important",
                            marginLeft: 0,
                        }
                    }
                },
                popper: {
                    borderRadius: theme.customization.borderRadius + 'px',
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: theme.customization.navType === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                select: {
                    fontSize: '28px'
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.customization.navType === 'dark' ? theme.colors.darkLevel1 : theme.colors.primaryDark,
                    background: theme.customization.navType === 'dark' ? theme.darkTextPrimary : theme.colors.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                root: {
                    "& .Mui-selected": {
                        "background": "transparent !important"
                    },
                    "& .Mui-selected.Mui-focused": {
                        "background": "transparent !important"
                    },
                    "& :hover": {
                        "background": "transparent !important"
                    }
                },
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme.customization.navType === 'dark' ? theme.colors.darkPaper : theme.colors.primaryLight,
                    textTransform: 'initial',
                    '& .MuiTabs-flexContainer': {
                        borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 20 : theme.colors.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme.customization.navType === 'dark' ? theme.colors.darkTextSecondary : theme.colors.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    button:{
                        textTransform: 'initial'
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0',

                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: theme.customization.navType === 'dark' ? theme.colors.darkTextPrimary + 15 : theme.colors.grey200,
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme.heading,
                        fontWeight: 500
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.customization.navType === 'dark' ? theme.colors.darkLevel1 : theme.paper,
                    background: theme.customization.navType === 'dark' ? theme.colors.grey50 : theme.colors.grey700
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    '& .MuiButtonBase-root': {
                        textTransform: 'initial'
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides : {
                root : {
                    border: 'none',
                    backgroundColor: 'transparent',
                    borderRadius:'0',
                    '> thead > tr > th': {
                        'backgroundColor' : 'transparent',
                        'borderBottom': '1px solid #000',
                        fontWeight: 500,
                        'textTransform' : 'uppercase',
                        'minHeight' : '50px',
                        'maxHeight' : '50px',
                        'fontSize' : '.75rem !important',
                        'lineHeight' : '.875rem',
                        color: theme.colors.black,
                        paddingBottom: '0.3rem',
                        '&:focus, &:focus-within' : {
                            'border' : 'none',
                            'outline' : 'none'
                        }
                    },
                    '> tbody > tr': {
                        borderColor: 'rgba(0,0,0,.08)',
                        'backgroundColor' : 'transparent',
                        '&:nth-child(even)': {
                            backgroundColor: 'rgba(0,0,0,.02)'
                        },
                    }
                }
            }
        },
         MuiDataGrid : {
            styleOverrides : {
                root : {
                    border: 'none',
                    backgroundColor: 'transparent',
                    borderRadius:'0',
                    '& .MuiDataGrid-cell': {
                       // border: '1px solid ' + theme.colors.primaryLight,
                        borderColor: 'rgba(0,0,0,.08)',
                        backgroundColor:'transparent',
                        '&:focus, &:focus-within' : {
                            'outline':'none'
                        }
                    },
                    '& .MuiDataGrid-columnsContainer' : {
                        'color':  theme.colors.grey900,
                        'borderBottom': 'none',
                        minHeight: '47px !important',
                        maxHeight: '47px !important',
                    },
                    '& .MuiDataGrid-window' : {
                        'top' : '47px!important'
                    },
                    '& .MuiDataGrid-columnHeaderWrapper' : {
                        'backgroundColor' : theme.colors.background,
                        'borderBottom': '1px solid #000'
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 900
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        'borderBottom': '1px solid #000'
                    },
                    '& .MuiDataGrid-columnHeader' : {
                        'textTransform' : 'uppercase',
                        'minHeight' : '50px',
                        'maxHeight' : '50px',
                        'fontSize' : '.75rem',
                        'lineHeight' : '.875rem',
                        fontWeight: 500,
                        color: theme.colors.black,
                        display: 'flex',
                        alignItems: 'flex-end',
                        paddingBottom: '0.3rem',
                        '&:focus, &:focus-within' : {
                            'border' : 'none',
                            'outline' : 'none'
                        }
                    },
                    '& .MuiDataGrid-columnHeaderDraggableContainer' : {
                        '& .drag-icon' : {
                            display : 'none',
                            'position' : 'absolute',
                            'left' : '0px',
                            'top' : '12px'
                        },
                        '&:hover' : {
                            '& .drag-icon' : {
                                fill : '#ffffff',
                                display : 'block'
                            }
                        }

                    },
                    '& .MuiDataGrid-columnHeaderTitleContainer' : {

                        'padding' : '0 0 0 12px'
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer' : {
                        'padding' : '0 6px'
                    },
                    '& .MuiDataGrid-cell--textLeft' : {
                        'paddingLeft' : '22px'
                    },
                    '& .MuiDataGrid-columnHeader--sortable' : {
                        '& .MuiDataGrid-sortIcon': {
                            'fill' : theme.colors.primaryMain,
                            fontSize: 14
                        },
                        '& .icon' : {
                            'fill' : theme.colors.primaryMain
                        },
                        '&[aria-sort="ascending"] .up' : {
                            'display' : 'none'
                        },
                        '&[aria-sort="descending"] .down' : {
                            'display' : 'none'
                        },
                        '& .MuiButtonBase-root.MuiIconButton-root' : {
                            'borderRadius' : '0',
                            '&:hover' : {
                                'backgroundColor' : 'transparent'    
                            },      
                        },
                        '& .sort-icon-wrap' : {
                            'position' : 'relative',
                            'width':'30px',
                            'height' : '31px',
                            '& .up, .down' : {
                                'position' : 'absolute',
                                'left' : '3px'
                            },
                            '& .up' : {
                                'top' : '0'
                            },
                            '& .down' : {
                                'bottom' : '0'
                            }
                        }
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        color: theme.colors.grey200,
                        justifyContent: 'flex-end'
                    },
                    '& .MuiCheckbox-root' : {
                        '& .MuiSvgIcon-root' : {
                            'fill' : theme.colors.primaryMain
                        },
                        '&:hover' : {
                            'backgroundColor' : 'transparent'
                        }
                    },
                    '& .MuiDataGrid-iconSeparator' : {
                        'display' : 'none',
                        fill : theme.colors.primaryMain
                    },
                    '& .MuiDataGrid-columnSeparator--resizable' : {
                        'minHeight' : '50px!important',
                        width: '24px',
                        position: 'absolute',
                        right: '-12px',
                        '&:hover' : {
                            '& .MuiDataGrid-iconSeparator' : {
                                'display' : 'block'
                            }
                        }
                    },
                    '& .MuiDataGrid-row' : {
                        'backgroundColor' : 'transparent',
                        '&:nth-child(even)': {
                            backgroundColor: 'rgba(0,0,0,.02)'
                        },
                        '&:hover' : {
                            'backgroundColor' : theme.colors.primaryLight + ' !important',
                            color: theme.colors.primaryMain + ' !important',
                        },
                        '&.Mui-selected' : {
                            'backgroundColor' : 'transparent',
                            color: theme.colors.primaryMain + ' !important',
                            '&:hover' : {
                                'backgroundColor' : theme.colors.primaryLight + ' !important',
                            },

                        }
                    },
                    '& .MuiDataGrid-toolbar' : {
                        //marginBottom : '2rem',
                        backgroundColor : 'white',
                        //borderTopLeftRadius : theme.borderRadius,
                        //borderTopRightRadius : theme.borderRadius,
                        padding:'1rem',
                        '& .MuiOutlinedInput-root' : {
                            paddingTop : '4px',
                            paddingBottom : '4px',

                            '&:hover .MuiOutlinedInput-notchedOutline' : {
                                'borderColor' : theme.colors.primaryMain,
                            },
                            '& .MuiSvgIcon-root' : {
                                color : theme.colors.primaryMain
                            }
                        },
                        '& .MuiButton-root' : {
                            border : '1px solid',
                            borderColor : theme.colors.primaryMain,
                            marginTop : '3px'
                        }
                    }
                    
                    
                }
            }

        },
        'MuiGridPanel' : {
            styleOverrides : {
                root : {
                    zIndex : '1000',
                    '& .MuiPaper-root' : {
                        borderRadius : '0'
                    },
                    '& .MuiDataGridColumnsPanel-dragIcon' : {
                        'display' : 'none'
                    }
                    
                }
            }
        }
    };
}
