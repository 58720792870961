import React, { useEffect, useState } from 'react';

import useAuth from '../hooks/useAuth';
import { useIdleTimer } from 'react-idle-timer';

import {
    Dialog,
    DialogContent,
    DialogContentText,
    Button
} from '@material-ui/core';
import config from "../config";
// project import

let countdownInterval;
let longInterval;

//-----------------------|| IDLE TIMER ||-----------------------//



const IdleTimer = () => {

    const { isLoggedIn, logout } = useAuth();
    const [ open, setOpen ] = useState(false);
    const [ idle, setIdle ] = useState(false);
    const [ idleStartTime, setIdleStartTime ] = useState(Number.MAX_SAFE_INTEGER); // some time in the distant future
    const [ timeoutCountdown, setTimeoutCountdown ] = useState(0);

    const [ longIntervalRunning, setLongIntervalRunning] = useState(false);
    const [ doingLongLastLogout, setDoingLongLastLogout] = useState(false);

    


    const handleOnIdle = () => {
        if (!idle) {
            console.log('handle idle');
            setIdleStartTime(Date.now());
            setIdle(true);
        }
             
        return;      
    }

    const handleClose = () => {
        console.log('handle close');
        setIdle(false);
        setIdleStartTime(Number.MAX_SAFE_INTEGER);
        clearInterval(countdownInterval);
        setOpen(false);
        
    };

   


    useEffect(() => {

        if (!isLoggedIn) {
            clearInterval(longInterval);
            clearInterval(countdownInterval);
            handleClose();
        }

        const idleLogout = async () => {
            try {
                await logout();
            } catch (err) {
                console.error(err);
            }
            setDoingLongLastLogout(false);
            setLongIntervalRunning(false);
            clearInterval(longInterval);
            
            clearInterval(countdownInterval);
            setIdle(false);
            setIdleStartTime(Number.MAX_SAFE_INTEGER);
            setOpen(false);
            
        }

        if (idle && isLoggedIn && !open) { // show countdown modal when user is inactive, computer on          
            console.log('setTimeoutCountdown');
            setTimeoutCountdown(30);
            setOpen(true);

            
            countdownInterval = setInterval(() => {
                let countDown = 31 - Math.floor((Date.now() - idleStartTime)/1000); // in case browser tab inactive
                //let countDown = 16 - Math.floor((Date.now() - idleStartTime)/1000); // in case browser tab inactive

                console.log(countDown);
                if (countDown > 0 ) { 
                    setTimeoutCountdown(countDown);
                } else { // do logout
                    console.log('idle logout');
                    idleLogout();
                }
            }, 1000);
        } 

        if (isLoggedIn && !longIntervalRunning) { // set interval timer to check since last run, for laptop closed
            //console.log('longIntervalRunning');
            setLongIntervalRunning(true);
            let longLastTime = Date.now();
            longInterval = setInterval(() => {
            //    console.log(Date.now() - longLastTime);
                if (Date.now() - longLastTime > (config.idleTimeout)) { // longer than 1 hour ago
                    console.log('long last log out');
                    setDoingLongLastLogout(true);
                    idleLogout();
                } else {
                    longLastTime = Date.now();
                }
            }, 1000);
        }
        
    }, [idle, isLoggedIn, open, idleStartTime, logout, longIntervalRunning]);



    useIdleTimer({
        timeout: config.idleTimeout,
        onIdle: handleOnIdle,
        debounce: 500,
        crossTab: {
            emitOnAllTabs: false
        }
    });

    return (
        <Dialog open={open} >  
            {doingLongLastLogout && (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Logging you out due to inactivity.
                  </DialogContentText>
                        
                </DialogContent>  

            )}

            {!doingLongLastLogout && (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You will be logged out in {timeoutCountdown} seconds due to inactivity.
                  </DialogContentText>
                        <Button onClick={handleClose} autoFocus color="primary" fullWidth size="large"  variant="contained">
                            Stay logged in
                        </Button>
                </DialogContent>  
            )}
              
        </Dialog>
        
    );
};


export default IdleTimer;
