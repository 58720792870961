
import * as actionTypes from "../../../../store/actions";
import produce from "immer";
import {NotificationPanelState} from "../interfaces/notification-panel.state";
import {NotificationPanelAction} from "../interfaces/notification-panel.action";
import {CommentInterface} from "../interfaces/comment.interface";

const initialState = {
    isLoadingComments: true,
    unreadOnly: false,
    comments: []
};

const notificationPanelReducer = (state: NotificationPanelState = initialState, action: NotificationPanelAction) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_PANEL_MARK_AS_READ:
            return produce(state, draft => {
                draft.comments.map((c: CommentInterface) => {
                    return c.isRead = c.id === action.commentId ? true : c.isRead;
                });
            });
        case actionTypes.NOTIFICATION_PANEL_MARKED_ALL_AS_READ:
            return produce(state, draft => {
                draft.comments.map((c: CommentInterface) => {
                    return c.isRead = action.commentIds.includes(c.id) ? true : c.isRead;
                });
            });
        case actionTypes.NOTIFICATION_PANEL_COMMENTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingComments = true;
                draft.unreadOnly = action.unreadOnly;
        });
        case actionTypes.NOTIFICATION_PANEL_COMMENTS_LOADED:
            return produce(state, draft => {
                draft.comments = action.comments;
                draft.isLoadingComments = false;
            });
    default:
        return state;
    }
};

export default notificationPanelReducer;