// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const SEND_VERIFY = 'SEND_VERIFY';
export const SEND_RESET = 'SEND_RESET';
export const DO_RESET = 'DO_RESET';
export const FAIL_RESET = 'FAIL_RESET';
export const CLEAR_RESET = 'CLEAR_RESET';
export const CLEAR_VERIFY = 'CLEAR_VERIFY';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const SET_MENU = 'SET_MENU';
export const UPDATE_FAVOURITES = 'UPDATE_FAVOURITES';

// action - customization reducer
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// actions - nav
export const TRIALS_NAV_LOAD = '@nav/TRIALS_NAV_LOAD';
export const TRIALS_NAV_LOADED = '@nav/TRIALS_NAV_LOADED';
export const TRIALS_NAV_TOGGLE_FAVOURITE = '@nav/TRIALS_TOGGLE_FAVOURITE';


// actions - onboarding
export const CHECK_ONBOARDING = '@onboarding/CHECK_ONBOARDING';
export const ONBOARDING_DONE = '@onboarding/ONBOARDING_DONE';
export const SHOW_ONBOARDING = '@onboarding/SHOW_ONBOARDING';
export const CLOSE_ONBOARDING = '@onboarding/CLOSE_ONBOARDING';

// action - preferences
export const PREFERENCES_SAVE_FAVOURITES = '@preferences/SAVE_FAVOURITES';
export const PREFERENCES_ADD_FAVOURITE = '@preferences/PREFERENCES_ADD_FAVOURITE';
export const PREFERENCES_REMOVE_FAVOURITE = '@preferences/PREFERENCES_REMOVE_FAVOURITE';

export * from '../views/single-trial/state/single-trial.actions';
export * from '../views/trials/patient/state/participant-details.actions';
export * from '../views/participant-portal/state/participant-dashboard.actions';
export * from '../views/reports/state/report.actions';
export * from '../views/trials/state/trials.actions';
export * from '../views/preferences/notifications/state/notifications-preferences.actions';
export * from '../views/preferences/preferred-times/state/preferred-times.actions';
export * from '../views/settings/user-management/state/user-management.actions';
export * from '../views/settings/trial-management/state/trial-management.actions';
export * from '../views/participant-upload/state/participant-management.actions';
export * from '../views/file-library/state/file-library.actions';
export * from '../shared/components/notification-panel/state/notification-panel.actions';
export * from '../views/notifications/state/notifications.actions';
export * from '../views/settings/participant-management/state/participant-management.actions';
