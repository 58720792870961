import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

// project imports
import useAuth from '../../hooks/useAuth';
import config from '../../config';
import {RecordStatusesEnum} from "../../shared/enums/record-statuses.enum";

//-----------------------|| REGISTER GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RegisterGuard = ({ children }) => {
    const { isLoggedIn, isRegistered, user } = useAuth();

    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }

    if (isRegistered && user.recordStatus !== RecordStatusesEnum.Unverified) {
    	return <Redirect to={config.defaultPath} />;
    }

    return children;
};

RegisterGuard.propTypes = {
    children: PropTypes.node
};

export default RegisterGuard;
