import React, {lazy, useEffect, useState} from 'react';
import {Route, Switch, useLocation, Redirect} from 'react-router-dom';
import useAuth from '../hooks/useAuth';


// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import {RoleEnum} from "../shared/enums/roleEnum";
import GuardedRoute from "../utils/route-guard/guarded.route";
import {UserPermissionTypesEnum} from "../shared/permissions/enums/user-permission-types.enum";
import {UserPermissionsEnum} from "../shared/permissions/enums/user-permissions.enum";
import PermissionRestricted from "../utils/component-guard/PermissionRestricted";
import {REDIRECT_TO_KEY} from "../shared/constants";


// top level page routing
const Dashboard = Loadable(lazy(() => import('../views/dashboard/dashboard.component')));


const SingleTrial = Loadable(lazy(() => import('../views/single-trial/selectedSingleTrial')));
const Reports = Loadable(lazy(() => import('../views/reports/container/report-management.container')));

const NotificationsOverview = Loadable(lazy(() => import("../views/preferences/notifications/notificationsOverview")));
const YourDetails = Loadable(lazy(() => import('../views/preferences/profile/profile')));
const Profile = Loadable(lazy(() => import('../views/preferences/profile/other/RecruiterProfile')));
const PreferredTimesContainer = Loadable(lazy(() => import('../views/preferences/preferred-times/containers/preferred-times.container')));
const DeleteAccountContainer = Loadable(lazy(() => import('../views/preferences/delete-account/containers/delete-account.container')));

const ParticipantManagementList = Loadable(lazy(() => import("../views/settings/participant-management/containers/participant-management-list.container")));
const UserManagementList = Loadable(lazy(() => import("../views/settings/user-management/containers/user-management-list.container")));
const TrialManagementList = Loadable(lazy(() => import("../views/settings/trial-management/containers/trial-management-list.container")));
const CompanyManagementPage = Loadable(lazy(() => import("../views/settings/company-management/containers/company-management-list.container")));
const AuditHistoryPage = Loadable(lazy(() => import("../views/settings/audit-history/containers/audit-history-list.container")));


const FourOhFour = Loadable(lazy(() => import('../views/404')));

const YourTrialsComponent = Loadable(lazy(() => import('../views/participant-portal/your-trials/containers/your-trials.container')));
const FindATrialContainer = Loadable(lazy(() => import('../views/participant-portal/find-a-trial/containers/find-a-trial.container')));
const ParticipantsContainer = Loadable(lazy(() => import('../views/participant-upload/containers/participant-management-list.container')));
const TrialDetailsContainer = Loadable(lazy(() => import('../views/participant-portal/trial-details/containers/trial-details.container')));
const JotformVerifyContainer = Loadable(lazy(() => import('../views/participant-portal/trial-details/containers/jotform-verify.container')));
const JotformFailedContainer = Loadable(lazy(() => import('../views/participant-portal/trial-details/containers/jotform-failed.container')));

const FileLibraryListContainer = Loadable(lazy(() => import('../views/file-library/containers/file-library-list.container')));
const NotificationsContainer = Loadable(lazy(() => import('../views/notifications/containers/notifications-list.container')));



//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const {user} = useAuth();
    const [defaultStartingRoute, setDefaultStartingRoute] = useState(null);

    const LogOut = () => {
        const { logout } = useAuth();

        const handleLogout = async () => {
            try {
                await logout();
            } catch (err) {
                console.error(err);
            }
        };

        React.useEffect(() => {
            handleLogout();
        });

        return (<React.Fragment/>);
    }

    useEffect( () => {
        if(user) {
            const hasRedirect= localStorage.getItem(REDIRECT_TO_KEY);
            if(!!hasRedirect){
                localStorage.removeItem(REDIRECT_TO_KEY);
                setDefaultStartingRoute(hasRedirect);
            } else {
                setDefaultStartingRoute([RoleEnum.PARTICIPANT].includes(user.role) ? "/your-trials" : "/dashboard");
            }
        } else {
            setDefaultStartingRoute(null);
        }
    },[user]);

    return (
        <Route path={[
            '/dashboard',
            '/trial/:tid',
            '/trial/:tid/mode/:mode',
            '/trial/:tid/mode/:mode/site/:sid',
            '/trial/:tid/site/:sid',
            '/reports',
            '/reports/:tid',
            '/file-library',
            '/file-library/:tid',
            '/file-library/:tid/patient/:pid/request/:rid',
            '/preferences',
            '/preferences/your-details',
            '/preferences/preferred-times',
            '/preferences/notification',
            '/preferences/delete-account',
            '/settings',
            '/settings/company',
            '/settings/trial',
            '/settings/user',
            '/settings/user-management',
            '/settings/trial-management',
            '/patients',
            '/sites',
            '/sponsors',
            '/your-trials',
            '/find-trial',
            '/your-details',
            '/login',
            '/landing',
            '/reset-password',
            '/profile-update',
            '*'
            ]}>
            
            <AuthGuard>
                <MainLayout tableMode={window.location.href.indexOf("trial/") > -1 && window.location.href.indexOf("mode/image") === -1}>
                    <Switch location={location} key={location.pathname}>
                            <Route path="/logout" component={LogOut} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/your-trials" component={YourTrialsComponent} />
                            <Route path="/find-a-trial" component={FindATrialContainer} />
                            <Route exact path="/trial-details" component={TrialDetailsContainer} />
                            <Route exact path="/trial-details/:tid" component={TrialDetailsContainer} />
                            <Route path="/trial-details/:tid/request/:rid" component={TrialDetailsContainer}/>

                            <Route path="/verify-survey/:sid" component={JotformVerifyContainer} />
                            <Route path="/failed-survey" component={JotformFailedContainer} />
                            <GuardedRoute path="/notifications" component={NotificationsContainer} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.Read]}/>
                            <GuardedRoute path="/participants" component={ParticipantsContainer} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.Read]} />

                            <GuardedRoute path="/trial/:tid/site/:sid" component={SingleTrial} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} parentResourceType={UserPermissionTypesEnum.Location} />
                            <GuardedRoute path="/trial/:tid/mode/:mode/site/:sid" component={SingleTrial} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} parentResourceType={UserPermissionTypesEnum.Location} />
                            <GuardedRoute path="/trial/:tid/mode/:mode" component={SingleTrial} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} />
                            <GuardedRoute path="/trial/:tid" component={SingleTrial} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} />

                            <GuardedRoute path="/reports/:tid" component={Reports} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadReports]} />
                            <GuardedRoute path="/reports" component={Reports} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadReports]} />

                            <GuardedRoute path="/file-library/:tid" component={FileLibraryListContainer} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} />
                            <GuardedRoute path="/file-library/:tid/patient/:pid/request/:rid" component={FileLibraryListContainer} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} />
                            {/*<GuardedRoute path="/file-library/:tid/site-id/:sid" component={FileLibraryListContainer} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} parentResourceType={UserPermissionTypesEnum.Location} />*/}
                            <GuardedRoute path="/file-library" component={FileLibraryListContainer} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} />

                            <Route exact path="/settings">
                                <PermissionRestricted type={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.RemoveUser]}>
                                     <Redirect to="/settings/trial-management"/>
                                </PermissionRestricted>
                                <PermissionRestricted type={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]}>
                                    <Redirect to="/settings/company-management"/>
                                </PermissionRestricted>
                            </Route>

                            <GuardedRoute path="/settings/company-management" component={CompanyManagementPage} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]} />
                            <GuardedRoute path="/settings/audit-history" component={AuditHistoryPage} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]} />                            

                            <GuardedRoute path="/settings/trial-management" component={TrialManagementList} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.RemoveUser]} />

                            <GuardedRoute path="/settings/user-management" component={UserManagementList} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]} />



                                <GuardedRoute
                                    path="/settings/participant-management"
                                    component={ParticipantManagementList}
                                    resourceType={UserPermissionTypesEnum.Company}
                                    permissions={[UserPermissionsEnum.ReadReports]}
                                    resourceId={1} />



                            <Route exact path="/preferences" >
                              <Redirect to="/preferences/your-details"/>
                            </Route>

                             <Route path="/preferences/your-details" component={YourDetails} />
                            <Route path="/profile-update" component={Profile} />
                            { user && [RoleEnum.PARTICIPANT].includes(user.role) &&  <Route path="/preferences/preferred-times" component={PreferredTimesContainer} /> }
                            <Route path="/preferences/notification" component={NotificationsOverview} />

                            <Route path="/preferences/delete-account" component={DeleteAccountContainer} />                             

                            <Route exact path="/">
                                { defaultStartingRoute !== null &&  <Redirect to={defaultStartingRoute}/> }
                            </Route>
                            <Route exact path="/reset-password">
                                { defaultStartingRoute !== null && <Redirect to={defaultStartingRoute}/> }
                            </Route>
                            <Route path="/landing">
                                { defaultStartingRoute !== null && <Redirect to={defaultStartingRoute}/> }
                            </Route>
                            <Route path="/login">
                                { defaultStartingRoute !== null && <Redirect to={defaultStartingRoute}/> }
                            </Route>
                             { user &&  <Route path="/signup">
                                { defaultStartingRoute !== null && <Redirect to={defaultStartingRoute}/> }
                            </Route> }
                            <Route path="*" component={FourOhFour} />
                    </Switch>
                </MainLayout>
            </AuthGuard>
            
            
            
        </Route>
    );
};

export default MainRoutes;


