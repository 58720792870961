import createMentionPlugin from "@draft-js-plugins/mention";
import mentionsStyles from "./styles/mention-styles.module.css";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import React from "react";
import Popover from "@mui/material/Popover";
import {Box, Stack} from "@mui/material";
import Avatar from "react-avatar";
import {Typography} from "@material-ui/core";

export const mentionPluginInstance = createMentionPlugin({
    entityMutability: 'IMMUTABLE',
    theme: mentionsStyles,
    mentionPrefix: '@',
    supportWhitespace: true,
    mentionComponent(mentionProps) {
        return (

            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <React.Fragment>
                                <span className={mentionProps.className} {...bindTrigger(popupState)}>
                                    {mentionProps.children}
                                </span>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <Stack direction="row" sx={{ padding: '1rem'}}>
                                <Box>
                                    { mentionProps.mention.groupUser && <Avatar aria-label="Profile" value={mentionProps.mention.groupIdentifier} size={'55'} textSizeRatio={1} round=".2rem"/> }
                                    { !mentionProps.mention.groupUser && <Avatar aria-label="Profile" name={mentionProps.mention.name} size={'55'} textSizeRatio={1} round=".2rem"/>}

                                </Box>
                                <Stack direction="column" alignItems="flex-start" justifyContent="center" sx={{ paddingLeft: '1rem'}}>
                                    <Typography variant="h4">{mentionProps.mention.name}</Typography>
                                    <Typography variant="subtitle3">{mentionProps.mention.companyName ? mentionProps.mention.companyName : `${mentionProps.mention.groupCount} users`}</Typography>
                                    <Typography variant="subtitle2">{mentionProps.mention.email}</Typography>
                                </Stack>
                            </Stack>
                        </Popover>

                    </React.Fragment>
                )}

            </PopupState>

        );
    },
});