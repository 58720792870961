/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPatientTrialAppointmentBookingRequest } from '../models/AddPatientTrialAppointmentBookingRequest';
import type { AddPatientTrialContactAttemptRequest } from '../models/AddPatientTrialContactAttemptRequest';
import type { AddPatientTrialNoteRequest } from '../models/AddPatientTrialNoteRequest';
import type { AddRemoveSiteTrialRequest } from '../models/AddRemoveSiteTrialRequest';
import type { AddRemoveTrialPatientRequest } from '../models/AddRemoveTrialPatientRequest';
import type { AddTrialDisqReasonRequest } from '../models/AddTrialDisqReasonRequest';
import type { AddTrialWithdrawalReasonRequest } from '../models/AddTrialWithdrawalReasonRequest';
import type { AddUpdateLocationTargetRequest } from '../models/AddUpdateLocationTargetRequest';
import type { AddWorkflowEventRequest } from '../models/AddWorkflowEventRequest';
import type { CreateTrialRequest } from '../models/CreateTrialRequest';
import type { CreateWorkflowRequest } from '../models/CreateWorkflowRequest';
import type { CreateWorkflowStageRequest } from '../models/CreateWorkflowStageRequest';
import type { CreateWorkflowTaskActionRequest } from '../models/CreateWorkflowTaskActionRequest';
import type { CreateWorkflowTaskRequest } from '../models/CreateWorkflowTaskRequest';
import type { GetParticipantsByTrialRequest } from '../models/GetParticipantsByTrialRequest';
import type { GetTaskCountFilterByTrialRequest } from '../models/GetTaskCountFilterByTrialRequest';
import type { InviteUsersToTrialRequest } from '../models/InviteUsersToTrialRequest';
import type { PatientWithdrawFromTrialRequest } from '../models/PatientWithdrawFromTrialRequest';
import type { ShowHideTrialDisqReasonRequest } from '../models/ShowHideTrialDisqReasonRequest';
import type { ShowHideTrialWithdrawalReasonRequest } from '../models/ShowHideTrialWithdrawalReasonRequest';
import type { UpdatePatientTrialStatusRequest } from '../models/UpdatePatientTrialStatusRequest';
import type { UpdateTrialDetailsRequest } from '../models/UpdateTrialDetailsRequest';
import type { UpdateTrialSiteStatusRequest } from '../models/UpdateTrialSiteStatusRequest';
import type { UpdateTrialStatusRequest } from '../models/UpdateTrialStatusRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TrialsService {

    /**
     * @param resultsPerPage 
     * @param pageNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrials(
resultsPerPage?: number,
pageNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrials',
            query: {
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
            },
        });
    }

    /**
     * @param id 
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrial(
id?: number,
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrial',
            query: {
                'id': id,
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialDetails(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialDetails',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param recruitingOnly 
     * @param resultsPerPage 
     * @param pageNumber 
     * @param search 
     * @param orderBy 
     * @param sortBy 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialNames(
userCognitoSub?: string,
recruitingOnly: boolean = true,
resultsPerPage: number = 50,
pageNumber: number = 1,
search: string = '',
orderBy: string = '',
sortBy: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialNames',
            query: {
                'userCognitoSub': userCognitoSub,
                'recruitingOnly': recruitingOnly,
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
                'search': search,
                'orderBy': orderBy,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialDqReasons(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialDqReasons',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialWdReasons(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialWdReasons',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetSummaries(
userCognitoSub: string = '',
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetSummaries',
            query: {
                'userCognitoSub': userCognitoSub,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @param userCognitoSub 
     * @param workflowTaskStates 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTaskStateCount(
trialId?: number,
userCognitoSub?: string,
workflowTaskStates?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTaskStateCount',
            query: {
                'trialId': trialId,
                'userCognitoSub': userCognitoSub,
                'workflowTaskStates': workflowTaskStates,
            },
        });
    }

    /**
     * @param trialId 
     * @param authorCognitoSub 
     * @param workflowTaskStates 
     * @returns binary OK
     * @throws ApiError
     */
    public static getApiTrialsExportPatientsByTrialId(
trialId?: number,
authorCognitoSub?: string,
workflowTaskStates?: number,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/ExportPatientsByTrialId',
            query: {
                'trialId': trialId,
                'authorCognitoSub': authorCognitoSub,
                'workflowTaskStates': workflowTaskStates,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsGetTaskCountFiltersByTrial(
userCognitoSub?: string,
requestBody?: GetTaskCountFilterByTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/GetTaskCountFiltersByTrial',
            query: {
                'userCognitoSub': userCognitoSub,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param userCognitoSub 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsGetParticipantsByTrialId(
userCognitoSub?: string,
requestBody?: GetParticipantsByTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/GetParticipantsByTrialId',
            query: {
                'userCognitoSub': userCognitoSub,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetPatientsByTrialId(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetPatientsByTrialId',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetHistoryExportPatientByTrialId(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetHistoryExportPatientByTrialId',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetPatientEventHistory(
userCognitoSub?: string,
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetPatientEventHistory',
            query: {
                'userCognitoSub': userCognitoSub,
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialSiteStatuses(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialSiteStatuses',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialLocations(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialLocations',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param companyId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialsByCompany(
companyId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialsByCompany',
            query: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetTrialsByUserPermission(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetTrialsByUserPermission',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsCreateTrial(
requestBody?: CreateTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/CreateTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddPatientTrialContactAttempt(
requestBody?: AddPatientTrialContactAttemptRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddPatientTrialContactAttempt',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddPatientTrialNote(
requestBody?: AddPatientTrialNoteRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddPatientTrialNote',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddPatientTrialAppointmentBooking(
requestBody?: AddPatientTrialAppointmentBookingRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddPatientTrialAppointmentBooking',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddTrialDisqualificationReason(
requestBody?: AddTrialDisqReasonRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddTrialDisqualificationReason',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsShowHideTrialDisqualificationReason(
requestBody?: ShowHideTrialDisqReasonRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/ShowHideTrialDisqualificationReason',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddTrialWithdrawalReason(
requestBody?: AddTrialWithdrawalReasonRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddTrialWithdrawalReason',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsShowHideTrialWithdrawalReason(
requestBody?: ShowHideTrialWithdrawalReasonRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/ShowHideTrialWithdrawalReason',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsInviteUsersToTrial(
requestBody?: InviteUsersToTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/InviteUsersToTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsUpdateTrialDetails(
requestBody?: UpdateTrialDetailsRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/UpdateTrialDetails',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsAddPatientToTrial(
requestBody?: AddRemoveTrialPatientRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/AddPatientToTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsPatientWithdrawFromTrial(
requestBody?: PatientWithdrawFromTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/PatientWithdrawFromTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsUpdatePatientTrialStatus(
requestBody?: UpdatePatientTrialStatusRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/UpdatePatientTrialStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsAddSiteToTrial(
requestBody?: AddRemoveSiteTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/AddSiteToTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsUpdateTrialStatus(
requestBody?: UpdateTrialStatusRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/UpdateTrialStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiTrialsUpdateTrialSiteStatus(
requestBody?: UpdateTrialSiteStatusRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Trials/UpdateTrialSiteStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetWorkflowByTrialId(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetWorkflowByTrialId',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrialsGetWorkflowEvents(
userCognitoSub?: string,
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Trials/GetWorkflowEvents',
            query: {
                'userCognitoSub': userCognitoSub,
                'trialId': trialId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsCreateWorkflowTaskAction(
requestBody?: CreateWorkflowTaskActionRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/CreateWorkflowTaskAction',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsCreateWorkflowTask(
requestBody?: CreateWorkflowTaskRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/CreateWorkflowTask',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsCreateWorkflowStage(
requestBody?: CreateWorkflowStageRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/CreateWorkflowStage',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsCreateWorkflow(
requestBody?: CreateWorkflowRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/CreateWorkflow',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddWorkflowEvent(
requestBody?: AddWorkflowEventRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddWorkflowEvent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiTrialsAddUpdateLocationTarget(
requestBody?: AddUpdateLocationTargetRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Trials/AddUpdateLocationTarget',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
