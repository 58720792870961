import * as React from "react";
import {Route} from "react-router-dom";
import {UserPermissionTypesEnum} from "../../shared/permissions/enums/user-permission-types.enum";
import usePermissions from "../../hooks/use-permissions.hook";
import {UserPermissionsEnum} from "../../shared/permissions/enums/user-permissions.enum";
import Loadable from "../../ui-component/Loadable";
import {lazy} from "react";
const FourOhFour = Loadable(lazy(() => import('../../views/404')));

const GuardedRoute = ({ component, resourceType, permissions, parentResourceType, ...rest }: {component: any, resourceType: UserPermissionTypesEnum, permissions: UserPermissionsEnum[], parentResourceType?: UserPermissionTypesEnum}) => {
    const resourceId: number|undefined = getResourceIdByType(resourceType, (rest as any).computedMatch.params);
    const parentResourceId: number|undefined = (parentResourceType) ? getResourceIdByType(parentResourceType, (rest as any).computedMatch.params) : undefined;
    const [allowed] = usePermissions(resourceType, permissions, resourceId, parentResourceType, parentResourceId);
    return allowed ? <Route {...rest} component={component}/> : <Route {...rest} component={FourOhFour} />;
}

const getResourceIdByType = (type: UserPermissionTypesEnum, params: any): number|undefined => {
    switch(type){
        case UserPermissionTypesEnum.Trial:
            return params.tid ? Number(params.tid) : undefined;
        case UserPermissionTypesEnum.Location:
            return params.sid ? Number(params.sid) : undefined;
        case UserPermissionTypesEnum.User:
            return params.uid ? Number(params.uid) : undefined;
        default:
            return undefined
    }
}

export default GuardedRoute;