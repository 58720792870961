import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@material-ui/core';
import { Grid, Typography,   Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// project imports
import LogoFull from './../../ui-component/LogoFull';
import Diamonds from './../../assets/images/diamond.svg'; // Import using relative path



const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${Diamonds})`,
    backgroundColor:'white',
    minHeight : '300px',
    backgroundSize : 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
        position : 'fixed',
        left:'50%',
        top:'0',
        bottom:'0',
        right:'0',
        width:'50%',
        minHeight : '500px'
    }
    
  },
}));



//-----------------------|| AUTHENTICATION BRANDING ||-----------------------//

const AuthBranding = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <Grid item xs={12} md={6} >
            <Grid container alignItems="center" justifyContent="flex-end" direction="column" className={classes.root}>
                <Grid item >

                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item sx={{ mb: 3 }}>
                                <RouterLink to="#">
                                    <LogoFull sx={{width:'115px'}}/>
                                </RouterLink>
                        </Grid>
                    </Grid>
                        
                    
                    <Typography variant="h1" align="center"  >
                        Evrima Connect
                        
                    </Typography>
                    <Typography variant="h4"align="center" color={theme.palette.grey[600]} sx={{maxWidth:'250px',mb:2}}>
                        Accelerating clinical research to help bring new treatments to patients who need them.
                        
                    </Typography>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item sx={{ mb: 3 }}>
                            <Typography sx={{textDecoration:'underline'}} color="primary" align="center" alignItems="center" justifyContent="center" component={Link} href="https://evrima.com" target="_blank">
                                evrima.com
                            </Typography>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
    );
};


export default AuthBranding;