/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

 import green from '@material-ui/core/colors/green';
 import red from '@material-ui/core/colors/red';
 import orange from '@material-ui/core/colors/orange';

export function themePalette(theme) {
    return {
        mode: theme.customization.navType,
        common: {
            black: theme.black,
            white:theme.white
        },
        primary: {
            extraLight : theme.colors.primaryExtraLight,
            light:  theme.colors.primaryLight,
            main: theme.colors.primaryMain,
            dark: theme.colors.primaryDark,
            200:  theme.colors.primary200,
            800:  theme.colors.primary800
        },
        secondary: {
            light:  theme.colors.secondaryLight,
            main:  theme.colors.secondaryMain,
            dark: theme.colors.secondaryDark,
            200:  theme.colors.secondary200,
            800: theme.colors.secondary800
        },
        error: {
            ...red,
            light: theme.colors.errorLight,
            main: theme.colors.errorMain,
            dark: theme.colors.errorDark,
            contrastText: '#fff'
        },
        orange: {
            light: theme.colors.orangeLight,
            main: theme.colors.orangeMain,
            dark: theme.colors.orangeDark,
            contrastText: '#fff'
        },
        warning: {
            ...orange,
            light: theme.colors.warningLight,
            main: theme.colors.warningMain,
            dark: theme.colors.warningDark,
            contrastText: '#fff'
        },
        success: {
            ...green,
            light: theme.colors.successLight,
            200: theme.colors.success200,
            main: theme.colors.successMain,
            dark: theme.colors.successDark,
            contrastText: '#fff'
        },
        info: {
            light: theme.colors.infoLight,
            main: theme.colors.infoMain,
            dark: theme.colors.infoDark
        },
        grey: {
            50: theme.colors.grey50,
            100: theme.colors.grey100,
            500: theme.colors.grey500,
            600: theme.colors.grey600,
            700: theme.colors.grey700,
            900: theme.colors.grey900
        },
        dark: {
            light: theme.colors.black,
            main: theme.colors.black,
            dark: theme.colors.black,
            800: theme.colors.black,
            900: theme.colors.black
        },
        text: {
            primary: theme.colors.black,
            secondary: theme.colors.black,
            dark: theme.colors.black,
            hint: theme.colors.grey600
        },
        background: {
            paper: theme.paper,
            background: theme.background
        },
        status : {
            eligible: theme.colors.eligible,
            disqualified:theme.colors.disqualified,
            pendingcontact:theme.colors.pendingcontact,
            referred:theme.colors.referred,
            enrolled:theme.colors.enrolled,
            recruiting: theme.colors.recruiting,
            ethicsapproved:theme.colors.ethicsapproved,
            trialcreated:theme.colors.trialcreated,
            closed:theme.colors.closed,
            blocked:theme.colors.blocked,
            noteligible : theme.colors.noteligible,
        },
        caution: {
            main: theme.colors.warningMain
        },
        new: {
            main: theme.colors.newBg
        },
        inProgress: {
            main: theme.colors.inProgressBg
        },
        referred: {
            main: theme.colors.passedBg
        },
        enrolled: {
            main: theme.colors.passedBg
        },
        withdrew: {
            main: theme.colors.failedBg
        },
        disqualified: {
            main: theme.colors.failedBg
        }

    };
}
