import PropTypes from 'prop-types';
import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';



// material-ui
import {AlertTitle, Box, Button, Typography, useTheme} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {  Grid } from '@material-ui/core';

// project imports

import LogoFull from './../../ui-component/LogoFull';
import AuthBranding from './AuthBranding';
import NotificationBarComponent from "../../shared/components/notification-bar/notification-bar.component";
import {isWebview} from "@dvlden/is-webview";
import {ConnectAlert} from "../../shared/connect-ui/components/connect-alert/connect-alert.component";
import {ContentCopy} from "@material-ui/icons";
import {CopyToClipboard} from "react-copy-to-clipboard/src";



// assets

const useStyles = makeStyles(theme => ({
  root : {
    backgroundColor:theme.palette.background,
    minHeight : '100vh'
  },
  formContainer: {
     justifyContent:'center',
    minHeight: 'calc(100vh - 68px)',
    [theme.breakpoints.up('md')]: {
      //  justifyContent:'flex-end'
    }
    
  },
}));

//-----------------------|| MINIMAL LAYOUT ||-----------------------//

const MinimalLayout = (props) => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const [copiedLabel, setCopiedLabel] = useState("Copy url");
    const [url] = useState("https://evrilink.com");
    const onShow = () => {

    }

    const onCopy = () => {
        setCopiedLabel("Copied!");
        setTimeout(() => {
            setCopiedLabel("Copy url");
        },500);
    }

    return (
        <React.Fragment >
            <NotificationBarComponent onShow={onShow} />
                <Grid container direction="row" justifyContent="center" className={classes.root}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Grid container  alignItems="center" className={classes.formContainer}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>


                                {  isWebview(window.navigator.userAgent) &&  (
                                    <React.Fragment>
                                        <ConnectAlert sx={{ margin: '0 1rem'}} severity="warning" icon={false}>
                                            <AlertTitle>In-app browser detected!</AlertTitle>
                                            <Box>
                                                <Typography variant="body1"> For the best experience and access to all features, we recommend opening the site in an external browser.</Typography>
                                                <Typography variant="body1" sx={{marginTop: '1rem'}}> Simply copy the url and paste it into your default browser.</Typography>

                                                <CopyToClipboard text={url}
                                                                 onCopy={onCopy}>
                                                <Button
                                                    sx={{marginTop: '1rem'}}
                                                    disableElevation
                                                    size="small"
                                                    color={"warning"}
                                                    variant="contained"
                                                    endIcon={<ContentCopy />}>
                                                    {copiedLabel}
                                                </Button>

                                                </CopyToClipboard>

                                            </Box>
                                        </ConnectAlert>
                                    </React.Fragment>
                                )}
                                
                                {props.children}

                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<AuthBranding />*/}
                </Grid>
            
        </React.Fragment>
    );
};

MinimalLayout.propTypes = {
    children: PropTypes.node
};

export default MinimalLayout;
