import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import AuthLayout from './../layout/AuthLayout';
import Loadable from '../ui-component/Loadable';
import RegisterGuard from './../utils/route-guard/RegisterGuard';

// top level page routing


const Register = Loadable(lazy(() => import('../views/authentication/register')));
const Profile = Loadable(lazy(() => import('../views/authentication/profile/auth-profile.component')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const AuthRoutesLoggedIn = () => {
    const location = useLocation();

    return (
        <Route path={[
            '/register',
            '/profile-update'
            ]}>
            <RegisterGuard>
            <AuthLayout>
                <Switch location={location} key={location.pathname}>
                    
                        <Route path="/register" component={Register} />
                        <Route path="/profile-update" component={Profile} />
                    
                </Switch>
            </AuthLayout>
            </RegisterGuard>
        </Route>
    );
};

export default AuthRoutesLoggedIn;


