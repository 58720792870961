import * as actionTypes from "../../../store/actions";
import produce from "immer";
import {ParticipantManagementState} from "../interfaces/participant-management.state";
import { ParticipantManagementAction } from "../interfaces/participant-management.action";

const initialState = {
    isLoadingParticipantImportedRecord: true,
    isLoadingCompanyParticipantImported: true,
    isLoadingTrial: true,
    companyParticipantImported: [],
    therapeuticAreas: [],
    companies: [],
    userTrials: [],
    participantImportedRecord: [],
    duplicateParticipantImportedRecord: [],
    duplicateParticipantImportedRecordByFilter: [],
    totalParticipantImportedRecord: 0
};

const participantManagementReducer = (state: ParticipantManagementState = initialState, action: ParticipantManagementAction) => {
    switch (action.type) {
        case actionTypes.COMPANY_PARTICIPANT_IMPORTED_LOAD:
            return produce(state, draft => {
                draft.isLoadingCompanyParticipantImported = true;
        });
        case actionTypes.COMPANY_PARTICIPANT_IMPORTED_LOADED:
            return produce(state, draft => {
                draft.isLoadingCompanyParticipantImported = false;
                draft.companyParticipantImported = action.companyParticipantImported;
        });
        case actionTypes.THERAPEUTICAREA_LOADED:
            return produce(state, draft => {
                draft.therapeuticAreas = action.therapeuticAreas;
        });
        case actionTypes.PARTICIPANT_IMPORTED_RECORD_LOAD:
            return produce(state, draft => {
                draft.isLoadingParticipantImportedRecord = true;
        });
        case actionTypes.PARTICIPANT_IMPORTED_RECORD_LOADED:
            return produce(state, draft => {
                draft.isLoadingParticipantImportedRecord = false;
                draft.participantImportedRecord = action.participantImportedRecord;
                draft.duplicateParticipantImportedRecord = action.duplicateParticipantImportedRecord;
                draft.totalParticipantImportedRecord = action.totalParticipantImportedRecord;
                draft.duplicateParticipantImportedRecordByFilter = action.duplicateParticipantImportedRecordByFilter
        });
        case actionTypes.COMPANY_PARTICIPANT_LOADED:
            return produce(state, draft => {
                draft.companies = action.companies;
        });
        case actionTypes.TRIAL_USER_LOADED:
            return produce(state, draft => {
                draft.userTrials = action.userTrials;
        });
        default:
            return state;
    }
};

export default participantManagementReducer;