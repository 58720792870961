import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';

// project imports
import useAuth from '../../hooks/useAuth';
import {RecordStatusesEnum} from "../../shared/enums/record-statuses.enum";
import {REDIRECT_TO_KEY, VALID_REDIRECT_PATHS} from "../../shared/constants";

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, isRegistered, user } = useAuth();
    const location = useLocation();

    if (!isLoggedIn) {
        if (location && VALID_REDIRECT_PATHS.includes(location.pathname)){
            localStorage.setItem(REDIRECT_TO_KEY,location.pathname);
        }
        return '';
    }

    if (isLoggedIn && !isRegistered) {
        return <Redirect to="/register" />;
    }

    if (isLoggedIn && isRegistered && user.recordStatus === RecordStatusesEnum.Unverified) {
        return <Redirect to="/profile-update" />;
    }

    if (isLoggedIn && isRegistered) {
        return children;
    }
    
    
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
