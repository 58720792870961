import * as React from 'react';
import {
    Button, Chip,
    CardActions, CardContent, CardMedia,
    Dialog, IconButton,
    Slide, Stack, Typography
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import styled from "@emotion/styled";
import {Close, InfoOutlined} from "@material-ui/icons";
import Box from "@mui/material/Box";
import {useContext, useEffect, useState} from "react";
import useAuth from "../../../hooks/useAuth";
import {CLOSE_ONBOARDING, ONBOARDING_DONE} from "../../../store/actions";
import {OnboardingTypeEnum} from "./onboardingTypeEnum";
import { Player, BigPlayButton  } from 'video-react';

import PermissionsContext from "../../../contexts/permissions.context";
import {RoleEnum} from "../../enums/role.enum";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: 0,
        width: 600
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Onboarding() {
    const showOnboarding = useSelector((state) => state.app.showOnboarding);
    const onboardingSlides = useSelector((state) => state.app.onboardingSlides);
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);
    const [slide, setSlide] = useState(0);
    const [showNext, setShowNext] = useState(false);
    const [showPrevious, setShowPrevious] = useState(false);
    const [showDone, setShowDone] = useState(false);
    const [player, setPlayer] = useState(null);
    const {user} = useAuth();
    const dispatch = useDispatch();
    const {hasRole, hasParentRole} = useContext(PermissionsContext);

    const handleClose = () => {
        setOpen(false);
        dispatch({ type: CLOSE_ONBOARDING});
        setTimeout(() => {
            setSlide(0);
            pauseVideo();
        },500);
    };

    const next = () => {
        if (showNext) {
            setSlide(slide+1);
            pauseVideo();
        }
    }

    const previous = () => {
        if (showPrevious) {
            setSlide(slide-1);
        }
    }

    const skip = () => {
        handleClose();
        dispatch({ type: ONBOARDING_DONE});
    }

    const done = () => {
        handleClose();
        dispatch({ type: ONBOARDING_DONE});
    }

    const pauseVideo = () => {
        if (player) {
            player.pause();
        }
    }


    useEffect(() => {
        if(user?.role) {
            const s = [];
            onboardingSlides.forEach(o => {

                for (let i=0;i< o.permissions.length;i++) {
                    if (hasParentRole([o.permissions[i].parentUserRole]) && o.permissions[i].parentUserRole === RoleEnum.COMPANY) {
                        if (hasRole(o.permissions[i].userRole, o.permissions[i].permissionType)) {
                            s.push(o);
                            break;
                        }
                    } else if(hasParentRole([o.permissions[i].parentUserRole]) && [RoleEnum.PARTICIPANT, RoleEnum.ADMIN].includes(o.permissions[i].parentUserRole)) {
                        s.push(o);
                        break;
                    }
                }
            });
            setSlides(s);
            setShowNext(slide < (s.length-1));
            setShowPrevious(slide > 0);
            setShowDone(slide === (s.length-1));

            if (s.length > 0){
                setOpen(showOnboarding);
            }
        }
    },[onboardingSlides, user, slide, showOnboarding, hasRole]);

    return (
        <div>
            <BootstrapDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                    <Box sx={{ position: 'relative'}}>
                        <Box sx={{position: 'absolute', top: 0, padding: '.5rem .5rem .5rem 1rem', width: '100%'}}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <span><b>{slide+1}</b> of <b>{slides.length}</b></span>
                                <IconButton aria-label="delete" size="small"  onClick={handleClose}>
                                    <Close fontSize="inherit" />
                                </IconButton>
                            </Stack>
                        </Box>

                        { slides[slide]?.type === OnboardingTypeEnum.IMG && slides[slide]?.img && <CardMedia
                            component="img"
                            alt={slides[slide]?.title}
                            image={slides[slide]?.img}
                        /> }

                        { slides[slide]?.type === OnboardingTypeEnum.VIDEO && slides[slide]?.video && <Box ><Player
                            ref={(player) => { setPlayer(player) }}
                            src={slides[slide]?.video}
                        >
                            <BigPlayButton position="center" />
                        </Player></Box> }

                    </Box>
                    <CardContent>
                        { slides[slide]?.tag && <Chip label={slides[slide]?.tag}  size="small" sx={{ marginBottom: '1rem', background: '#41b987', color: '#fff'}} /> }
                        <Typography gutterBottom variant="h4" component="div">{slides[slide]?.title}</Typography>
                        <Typography variant="body2" color="text.secondary">{slides[slide]?.description}</Typography>

                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            { slides[slide]?.link && <Button href={slides[slide]?.link} target={"_blank"} color={"primary"}  variant={"outlined"} startIcon={<InfoOutlined />}>More about the rebrand</Button> }
                             { !showDone && <Button color={"primary"} variant={"outlined"} onClick={skip}>Skip</Button> }
                        </Box>
                        <Stack direction={"row"} spacing={2}>

                            { showPrevious && <Button color={"primary"}  onClick={previous}>Previous</Button> }
                            { showNext && <Button color={"primary"}  variant={"contained"} onClick={next}>Next</Button> }
                            { showDone && <Button color={"primary"}  variant={"contained"} onClick={done}>Done</Button> }
                        </Stack>
                    </CardActions>
            </BootstrapDialog>
        </div>
    );
}
