

export const ORDINAL_TYPES = ['once', 'twice', '3 times', '4 times', '5 times'];
export const VALID_EMAIL_REGEX = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,20}$/;
export const TYPING_DEBOUNCE = 1000;

export const MARKETING_SOURCES = [
    'Family/Friend',
    'Email',
    'SMS',
    'Research Study Site',
    'Doctor/Specialist',
    'GP Practice',
    'Advertising:Facebook',
    'Advertising:Instagram',
    'Advertising:Google Search',
    'Advertising:Newspaper',
    'Advertising:Radio',
    'Advertising:Poster/Brochure',
    'Advertising:Television',
    'Advertising:Website/Newsletter',
];

export const REDIRECT_TO_KEY = 'redirectTo';
export const VALID_REDIRECT_PATHS = ['/trial-details'];