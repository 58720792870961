
import {ParticipantManagementState} from "../interfaces/participant-management.state";
import {ParticipantManagementAction} from "../interfaces/participant-management.action";
import * as actionTypes from "../../../../store/actions";
import produce from "immer";
import {PatientTrialStatus, PatientUIWorkflow, Trial} from "../../../../api/connect";
import {ParticipantAssociatedTrialModel} from "../models/participant-associated-trial.model";
import {ParticipantStatusEnum} from "../../../../shared/enums/participant-status.enum";


const initialState = {
    isLoadingParticipants: true,
    participants: [],
    totalParticipants: 0,
    selectedParticipant: null,
    selectedTrial: null,
    isLoadingParticipant: false,
    isVerifying: null,
    verifyError: false,
    isResettingPassword: false,
    resettingPasswordError: false,
    newPassword: '',
    trials: []
};

const patientManagementReducer = (state: ParticipantManagementState = initialState, action: ParticipantManagementAction) => {
    switch (action.type) {
        case  actionTypes.PARTICIPANT_MANAGEMENT_WITHDRAW:
            return produce(state, draft => {

                draft.trials = draft.trials.map(t => t.id === action.isWithdrawing ? {...t, isWithdrawing: true} : t);
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_WITHDRAWN:
            return produce(state, draft => {
                draft.trials = draft.trials.map(t => t.isWithdrawing ? {...t, isWithdrawing: false } : t);
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_LOAD:
            return produce(state, draft => {
                    draft.isLoadingParticipants = true;
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_LOADED:
            return produce(state, draft => {
                draft.isLoadingParticipants = false;
                draft.participants = action.participants;
                draft.totalParticipants = action.totalParticipants;
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_VERIFY:
            return produce(state, draft => {
                draft.isVerifying = action.verifying;
                draft.verifyError = false;
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_VERIFIED:
            return produce(state, draft => {
                draft.isVerifying = null;
                draft.verifyError = false;
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_VERIFY_ERROR:
            return produce(state, draft => {
                draft.isVerifying = null;
                draft.verifyError = true;
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_RESET_PASSWORD:
            return produce(state, draft => {
                draft.isResettingPassword = true;
                draft.resettingPasswordError = false;
                draft.newPassword = '';
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_PASSWORD_RESET:
            return produce(state, draft => {
                draft.isResettingPassword = false;
                draft.resettingPasswordError = false;
                draft.newPassword = action.newPassword;
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_PASSWORD_RESET_ERROR:
            return produce(state, draft => {
                draft.isResettingPassword = false;
                draft.resettingPasswordError = true;
                draft.newPassword = '';
            });
        case  actionTypes.PARTICIPANT_MANAGEMENT_REMOVE_PASSWORD:
            return produce(state, draft => {
                draft.isResettingPassword = false;
                draft.resettingPasswordError = false;
                draft.newPassword = '';
            });
        case actionTypes.PARTICIPANT_MANAGEMENT_SELECT_USER:
            return produce(state, draft => {
                draft.isLoadingParticipant = true;
            });
        case actionTypes.PARTICIPANT_MANAGEMENT_SELECTED_USER:
            return produce(state, draft => {
                draft.selectedParticipant = action.participant;
                draft.isLoadingParticipant = false;
                draft.trials = action.participant.trials?.map((t: Trial) => {
                    const trialStatus = action.participant.uiWorkflows?.find((w: PatientUIWorkflow) => w.trialId === t.id);
                    return new ParticipantAssociatedTrialModel(t.id, t.name, t.trialIdentifier, trialStatus?.overallStatus as unknown as ParticipantStatusEnum, trialStatus?.stageLabel, false);
                }) || [];
            });
        case actionTypes.PARTICIPANT_MANAGEMENT_UNSELECT_USER:
            return produce(state, draft => {
                draft.selectedParticipant = null;
                draft.isLoadingParticipant = false;
                draft.trials = [];
            });
        default:
            return state;
    }
}

export default patientManagementReducer;