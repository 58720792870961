// action - state management
// import * as actionTypes from './actions';

const initialState = {
    patientStatusList : [
        'New', //0
        'Eligible',//1
        'Disqualified',//2
        'Referred',//3
        'Enrolled',//4
        'Pending Contact',//5
        'Withdrew',//6
        'BookedForScreening',//7
        'Deleted',//8
        'Not eligible' //9
    ]
};

//-----------------------|| SNACKBAR REDUCER ||-----------------------//

const staticReducer = (state = initialState, action) => {
    return state;
};

export default staticReducer;
