import React from 'react';
import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import {applyMiddleware, compose, createStore} from 'redux';
import { Provider } from 'react-redux';

// load mock apis
import './_mockApis';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import reducer from './store/reducer';

// style + assets
import './assets/scss/style.scss';
import thunkMiddleware from 'redux-thunk';




//-----------------------|| REACT DOM RENDER  ||-----------------------//

const composeEnhancers =
    (!config.prod &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
