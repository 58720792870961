import React from "react";

declare module '@material-ui/core/styles' {
    interface TypographyVariants {
        subtitle: React.CSSProperties;
        subtitle3: React.CSSProperties;
        body3: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        subtitle?: React.CSSProperties;
        subtitle3?: React.CSSProperties;
        body3?: React.CSSProperties;
    }
}

declare module '@material-ui/core/Typography' {
    interface TypographyPropsVariantOverrides {
        subtitle: true;
        subtitle3: true;
        body3: true;
    }
}

export function themeTypography(theme: any) {

    return {
        fontFamily: '"Poppins", "Franklin Gothic Medium", Tahoma, sans-serif;',
        h6: {
            fontWeight: 600,
            color: theme.colors.black,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: theme.colors.black,
            fontWeight: 600
        },
        h4: {
            fontSize: '1rem',
            color: theme.colors.black,
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.colors.black,
            fontWeight: 600,
            lineHeight:'1.75rem'
        },
        h2: {
            fontSize: '1.5rem',
            color: theme.colors.black,
            fontWeight: 600,
            lineHeight:'2rem',
        },
        h1White: {
            fontSize: '2.125rem',
            color: "#fff",
            fontWeight: 600,
            lineHeight:'2.625rem'
        },
        subtitleWhite: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: "rgba(255,255,255,0.6)"
        },
        h1: {
            fontSize: '2.125rem',
            color: theme.colors.black,
            fontWeight: 600,
            lineHeight:'2.625rem'
        },
        subtitle: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.colors.grey700
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.colors.black
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 500,
            color: theme.colors.grey700
        },
        subtitle3: {
            fontSize: '0.8rem',
            fontWeight: 500,
            color: theme.colors.black
        },
        caption: {
            fontSize: '0.75rem',
            color: theme.colors.black,
            fontWeight: 400
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.colors.black
        },
        body3: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em',
            color: theme.colors.grey700
        },
        title: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em',
            color: theme.colors.secondary
        },
        customInput: {
            marginTop: 8,
            marginBottom: 8,
            '& > label': {
                top: '23px',
                left: 0,
                color: theme.colors.grey600,
                '&[data-shrink="false"]': {
                    top: '5px'
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            backgroundColor:theme.background,
            width: '100%',
            marginTop: '66px',
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.colors.black,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: theme.colors.black,
            textTransform: 'capitalize'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        }
    };
}
