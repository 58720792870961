import * as actionTypes from './audit-history.actions';
import produce from 'immer';
import { AuditHistoryState } from '../interfaces/audit-history.state';
import { AuditHistoryAction } from '../interfaces/audit-history.action';

const initialState = {
  isLoading: false,
  entries: [],
  total: 0,
};

const auditHistoryReducer = (
  state: AuditHistoryState = initialState,
  action: AuditHistoryAction
) => {
  switch (action.type) {
    case actionTypes.AUDIT_HISTORY_LOAD:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.entries = [];
        draft.total = 0;        
      });
    case actionTypes.AUDIT_HISTORY_LOADED:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.entries = action.entries ? action.entries : [];
        draft.total = action.total;        
      });
    default:
      return state;
  }
};

export default auditHistoryReducer;
