import React, { lazy } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';

import AuthLayout from './../layout/AuthLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// login routing
const Landing = Loadable(lazy(() => import('../views/authentication/landing')));
const ExpiredDate = Loadable(lazy(() => import('../views/expired-date')));
const AuthLogin = Loadable(lazy(() => import('../views/authentication/login')));
const AuthSignUp = Loadable(lazy(() => import('../views/authentication/signup')));
const ResendVerification = Loadable(lazy(() => import('../views/authentication/resend-verification')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/reset-password')));
//-----------------------|| AUTH ROUTING ||-----------------------//

const AuthRoutesLoggedOut = () => {
    const location = useLocation();

    return (
        <Route path={[
            '/landing',
            '/login', 
            '/signup',
            '/resend-verification',
            '/forgot-password',
            '/reset-password',
            '/expired-date',
            '*'
        ]}>
            <GuestGuard>
                <AuthLayout>
                <NavMotion>
                    <Switch location={location} key={location.pathname}>
                        <Route path="/expired-date" component={ExpiredDate} />
                        <Route path="/landing" component={Landing} />
                        <Route path="/login" component={AuthLogin} />
                        <Route path="/signup" component={AuthSignUp} />
                        <Route path="/resend-verification" component={ResendVerification} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/reset-password" component={ResetPassword} />
                        <Route path="*">
                            <Redirect to="/landing"/>
                        </Route>
                            
                            
                        
                    </Switch>
                    </NavMotion>
                </AuthLayout>
            </GuestGuard>
        </Route>
    );
};

export default AuthRoutesLoggedOut;
