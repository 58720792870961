import * as actionTypes from '../../../store/actions';
import produce from "immer";
import {
    SINGLE_TRIAL_LOAD,
    SINGLE_TRIAL_LOADED,
    SINGLE_TRIAL_WORKFLOW_LOADED,
    SINGLE_TRIAL_PREFERENCES_LOAD,
    SINGLE_TRIAL_PREFERENCES_SAVE,
    SINGLE_TRIAL_RESULTS_PER_PAGE_CHANGE,
    SINGLE_TRIAL_PAGE_NUMBER_CHANGE,
    SINGLE_TRIAL_PARTICIPANTS_LOAD,
    SINGLE_TRIAL_PARTICIPANTS_LOADED,
    SINGLE_TRIAL_FILTER_LOADED,
    SINGLE_TRIAL_FILTER_LOAD,
    SINGLE_TRIAL_SEARCH_CHANGE,
    SINGLE_TRIAL_MODE_CHANGE
} from "../../../store/actions";
import {UserFavouriteTypeEnum} from "../../../shared/enums/userFavouriteTypeEnum";
import {TrialsService} from "../../../api/connect";
import {OrderByEnum} from "../../../shared/enums/order-by.enum";
import {ParticipantTableModel} from "../participant/models/participant-table.model";
import moment from "moment";

const initialState = {
    trial: null,
    participants: null,
    workflow: null,
    isLoading: true,
    taskFilters: [],
    isTaskFiltersLoading: false,
    siteFilters: [],
    preferences: {
        pageSize: 50,
        filters: {},
    },
    mode: 'stage1',
    siteFilteredBy: null,
    pageNumber: 0,
    totalParticipants: 0,
    isParticipantsLoading: true,
    search: ''
};

const singleTrialReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SINGLE_TRIAL_FILTER_LOAD:
            return produce(state, draft => {
                draft.taskFilters = [];
                draft.isTaskFiltersLoading = true;
            });
        case actionTypes.SINGLE_TRIAL_FILTER_LOADED:
            return produce(state, draft => {
                draft.taskFilters = action.taskFilters;
                draft.isTaskFiltersLoading = false;
            });
        case actionTypes.SINGLE_TRIAL_SITE_FILTER_SELECT:
            return produce(state, draft => {
                draft.siteFilteredBy = action.siteId;
            });
        case actionTypes.SINGLE_TRIAL_MODE_CHANGE:
            return produce(state, draft => {
                draft.mode = action.mode;
            });
        case actionTypes.SINGLE_TRIAL_RESULTS_PER_PAGE_CHANGE:
            return produce(state, draft => {
                draft.preferences.pageSize = action.resultsPerPage;
            });
        case actionTypes.SINGLE_TRIAL_PAGE_NUMBER_CHANGE:
            return produce(state, draft => {
                draft.pageNumber = action.pageNumber;
            });
        case actionTypes.SINGLE_TRIAL_SORT_BY_CHANGE:
            return produce(state, draft => {
                draft.sortBy = action.sortBy;
            });
        case actionTypes.SINGLE_TRIAL_ORDER_BY_CHANGE:
            return produce(state, draft => {
                draft.orderBy = action.orderBy;
            });
        case actionTypes.SINGLE_TRIAL_SEARCH_CHANGE:
            return produce(state, draft => {
                draft.search = action.search;
            });
        case actionTypes.SINGLE_TRIAL_TOGGLE_FAVORITE:
            return produce(state, draft => {
                draft.trial.isFavourite = !draft.trial.isFavourite;
            });
        case actionTypes.SINGLE_TRIAL_WORKFLOW_LOADED:
            return produce(state, draft => {
                draft.workflow = action.workflow;
            });
        case actionTypes.SINGLE_TRIAL_LOAD:
            return produce(state, draft => {
                draft.isLoading = true;
                draft.trial = null;
               draft.totalParticipants = 0;
               draft.participants = [];
            });
        case actionTypes.SINGLE_TRIAL_LOADED:
            return produce(state, draft => {
                draft.siteFilters = [];
                draft.trial = action.trial;
                draft.trial['isFavourite'] = (action.favourites && action.favourites.map(f => Number(f.favouriteValue)).includes(action.trial.id));
                draft.isLoading = false;
            });
        case actionTypes.SINGLE_TRIAL_PARTICIPANTS_LOAD:
            return produce(state, draft => {
                draft.isParticipantsLoading = true;
                draft.totalParticipants = 0;
                draft.participants = [];
            });
        case actionTypes.SINGLE_TRIAL_PARTICIPANTS_LOADED:
            return produce(state, draft => {

                draft.totalParticipants = action.totalParticipants;

                const participants =  action.participants.map((p) => {
                    const participant = new ParticipantTableModel();
                    participant.id = `${draft.trial?.trialIdentifier}-${p.Id}`;
                    participant.cognitoSub = p.CognitoSub;
                    participant.participantName = p.ParticipantName;
                    participant.dateOfTrialRegistration = p.RegoDate;
                    participant.email = p.Email;
                    participant.phoneNumber = p.Phone;
                    participant.preferredTimesPreference = p.PreferredTimesPreferences ? JSON.parse(p.PreferredTimesPreferences) : null;
                    participant.siteReferredToId = p.ReferredSite;
                    participant.dateReferred = (p.DateReferred) ? moment.utc(p.DateReferred).local().format() : '';

                    for (const key of Object.keys(p)) {
                        if(!['Id','CognitoSub','ParticipantName','RegoDate','Email','Phone','PreferredTimesPreferences','ReferredSite'].includes(key)){
                            participant[`${key}`] = p[key];
                        }
                    }

                    return participant;
                });

                draft.participants = participants;
                draft.isParticipantsLoading = false;
            });
        case actionTypes.SINGLE_TRIAL_PREFERENCES_LOAD:
          return produce(state, draft => {
              draft.preferences = action.preferences;
          });
        case actionTypes.SINGLE_TRIAL_PREFERENCES_SAVE:
            return produce(state, draft => {
                draft.preferences = action.preferences;
            });
        default:
          return state;
    }
};

export default singleTrialReducer;


// Effects


export function getTrialById(trialId,
                             userCognitoSub,
                             participantCognitoSub = null,
                             siteId = null,
                             silent = false) {
    return async (dispatch, getState) => {

        dispatch(loadPreferences());

        if (!silent) {
            dispatch({type: SINGLE_TRIAL_LOAD});
        }

        const favourites = getState().app.favourites;
        const state = getState().singleTrial;


            return TrialsService.getApiTrialsGetTrial(Number(trialId),userCognitoSub)
                .then(trial => {

                    TrialsService.getApiTrialsGetWorkflowByTrialId(Number(trialId))
                        .then(workflow => {
                            dispatch({type: SINGLE_TRIAL_WORKFLOW_LOADED, workflow: workflow.results});
                            dispatch({
                                type: SINGLE_TRIAL_LOADED,
                                trial: trial.results,
                                favourites: favourites.filter(f => f.favouriteType === UserFavouriteTypeEnum.TRIAL)
                            });
                            dispatch(getParticipants(userCognitoSub));
                        })
                        .catch(error => console.error(error));
                })
                .catch(error => console.error(error));
        }
}

export function getFilterByTrialId(trialId, userCognitoSub) {
    return async (dispatch, getState) => {

        dispatch({ type: SINGLE_TRIAL_FILTER_LOAD });


        const state = getState().singleTrial;
        // get filter preferences
        const key = `trial-${state.trial?.id}-${state.mode}`;
        const filters = (state.trial) ? state.preferences.filters[key] : null;

        return TrialsService.postApiTrialsGetTaskCountFiltersByTrial(userCognitoSub,{
                    trialId: Number(trialId),
                    siteId: state.siteFilteredBy,
                    stagePriority: Number(state.mode.replace('stage', '')),
                    showRegistrations: filters ? filters.showRegistrationsFilter : false,
                })
                    .then(filters => {
                        dispatch({
                            type: SINGLE_TRIAL_FILTER_LOADED,
                            taskFilters: filters.results || []
                        });
            })
            .catch(error => console.error(error));
    }
}

export function pageChange(page, userCognitoSub) {
    return async (dispatch, getState) => {
        dispatch({type: SINGLE_TRIAL_PAGE_NUMBER_CHANGE, pageNumber: page});
        dispatch(getParticipants(userCognitoSub));
    };
}

export function searchChange(searchText, userCognitoSub) {
    return async (dispatch, getState) => {
        dispatch({type: SINGLE_TRIAL_PAGE_NUMBER_CHANGE, pageNumber: 0});
        dispatch({type: SINGLE_TRIAL_SEARCH_CHANGE, search: searchText});
        dispatch(getParticipants(userCognitoSub));
    };
}

export function modeChange(mode, userCognitoSub, silent = false) {
    return async (dispatch, getState) => {
        dispatch({type: SINGLE_TRIAL_PAGE_NUMBER_CHANGE, pageNumber: 0});
        dispatch({type: SINGLE_TRIAL_MODE_CHANGE, mode});
        if(!silent){
            dispatch(getParticipants(userCognitoSub));
        }
    };
}


export function getParticipants(userCognitoSub) {

    return async (dispatch, getState) => {
        dispatch({ type: SINGLE_TRIAL_PARTICIPANTS_LOAD });

        const state = getState().singleTrial;

        // get filter preferences
        const key = `trial-${state.trial?.id}-${state.mode}`;
        const filters = (state.trial) ? state.preferences.filters[key] : null;

        if (state.trial) {
            dispatch(getFilterByTrialId(state.trial.id, userCognitoSub));
            TrialsService.postApiTrialsGetParticipantsByTrialId(userCognitoSub,{
                trialId: state.trial.id,
                resultsPerPage: state.preferences.pageSize,
                pageNumber: state.pageNumber + 1,
                sortBy: filters && filters.sortModel && filters.sortModel.length ? filters.sortModel[0].sort : OrderByEnum.DESC,
                orderBy: filters && filters.sortModel && filters.sortModel.length ? filters.sortModel[0].field : 'created',
                search: state.search,
                registrationSortBy: filters && filters?.sortByFilter === 'Oldest' ? 'asc' : 'desc',
                siteId: state.siteFilteredBy,
                showRegistrations: filters ? filters.showRegistrationsFilter : false,
                stagePriority: Number(state.mode.replace('stage','')),
                taskId: filters && filters.taskStatusFilter && filters.taskStatusFilter.length ? filters.taskStatusFilter[0].id : null,
                taskPriority: filters && filters.taskStatusFilter && filters.taskStatusFilter.length ? filters.taskStatusFilter[0].priority : null,
                taskState: filters && filters.taskStatusFilter && filters.taskStatusFilter.length ? filters.taskStatusFilter[0].state : null,
                referredInterval: filters ? filters.referredInterval : 0,
            })
                .then(people => {
                    dispatch({
                        type: SINGLE_TRIAL_PARTICIPANTS_LOADED,
                        participants: people.results,
                        totalParticipants: people.totalRows
                    })
                })
        }
    }
}

export function loadPreferences() {
  return async (dispatch) => {
    const preferences = {
      pageSize: 50,
      filters: {},
    };
    const storedPrefs = localStorage.getItem('ev_preferences');
    if (storedPrefs) {
      const parsedPrefs = JSON.parse(storedPrefs);
      preferences.pageSize = parsedPrefs.pageSize || preferences.pageSize;
      preferences.filters = parsedPrefs.filters || preferences.filters;
    }
    dispatch({ type: SINGLE_TRIAL_PREFERENCES_LOAD, preferences });
  }
}

export function savePreferences(preferences, userCognitoSub) {
  return async (dispatch) => {
    localStorage.setItem('ev_preferences', JSON.stringify(preferences));
    dispatch({ type: SINGLE_TRIAL_PREFERENCES_SAVE, preferences });
    dispatch({ type: SINGLE_TRIAL_RESULTS_PER_PAGE_CHANGE, resultsPerPage: preferences.pageSize });
    dispatch({ type: SINGLE_TRIAL_PAGE_NUMBER_CHANGE, pageNumber: 0});
    dispatch(getParticipants(userCognitoSub));
  }
}



