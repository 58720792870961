import React from 'react';
// import {  useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {Box, CssBaseline} from '@material-ui/core';
import clsx from 'clsx';
import AppBar from "../../shared/components/app-bar/app-bar.component";


// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: '3rem'
    },
    content: {
        ...theme.typography.mainContent,

    }
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children, tableMode }) => {

    const [isNotificationBarShowing, setIsNotificationBarShowing] = React.useState(false);
    const classes = useStyles();
    // const theme = useTheme();

    const onShow = (show) => {
        setIsNotificationBarShowing(show);
    }



    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* header */}
            <AppBar onNotificationShow={onShow} />

            {/* main content */}

            <Box component={"main"}
                 sx={{ marginTop: (isNotificationBarShowing) ? '116px !important' : 'initial' }}
                className={clsx([
                    classes.content
            ])}
            >
                <div>{children}</div>
            </Box>
        </div>
    );
};

export default MainLayout;
