import {useEffect} from "react";
import useAuth from "../hooks/useAuth";
import {useIntercom} from "react-use-intercom";
import config from "../config";


const IntercomConnectProvider = ({children}: any) => {

    const {user} = useAuth() as any;
    const {boot} = useIntercom();

    useEffect(() => {

        //temporary solution
        const userTypes = [
            'OTHER',
            'PARTICIPANT',
            'SPONSOR',
            'SITE',
            'RECRUITER',
            'ADMIN',
        ];

        if (user && config.intercom.enabled) {
            boot({
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                customAttributes: {
                    cognitoSub: user.cognitoSub,
                    userType: userTypes[user.role],
                    state: user.state,
                    location: user.location
                },
            })
        }
    },[boot, user]);

    return [ children ];
}

export default IntercomConnectProvider;