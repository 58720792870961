import React from 'react';

import {Box} from '@material-ui/core';


import logo from './../assets/images/evrlink-logo-gray.png';





//-----------------------|| LOGO SVG ||-----------------------//

const LogoFull = (props) => {

    const {...others} = props;

    return (

        <Box  {...others}>
            <img src={ logo} alt="Evrima" style={{maxHeight:'100%',maxWidth:'100%'}}/>
        </Box>

         


    );
};

export default LogoFull;
