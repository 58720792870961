
import * as actionTypes from "../../../store/actions";
import produce from "immer";
import {NotificationsState} from "../interfaces/notifications.state";
import {NotificationsAction} from "../interfaces/notifications.action";
import {CommentInterface} from "../interfaces/comment.interface";
import {CommentTrialModel} from "../models/comment-trial.model";

const initialState = {
    isLoadingComments: true,
    isReloadComment: false,
    unreadOnly: false,
    totalPages: 0,
    comments: [],
    commentTrials: [],
    totalComments: 0,
    authors: []
};

const notificationsReducer = (state: NotificationsState = initialState, action: NotificationsAction) => {
    switch (action.type) {
        case actionTypes.NOTIFICATIONS_COMMENT_MARK_AS_READ:
            return produce(state, draft => {
                draft.comments.map((c: CommentInterface) => {
                    return c.isRead = c.id === action.commentId ? true : c.isRead;
                });
            });
        case actionTypes.NOTIFICATIONS_COMMENT_MARKED_ALL_AS_READ:
            return produce(state, draft => {
                draft.comments.map((c: CommentInterface) => {
                    return c.isRead = action.commentIds.includes(c.id) ? true : c.isRead;
                });
                
                draft.isReloadComment = true;
            });
        case actionTypes.NOTIFICATIONS_COMMENT_LOAD:
            return produce(state, draft => {
                draft.isLoadingComments = true;
                draft.unreadOnly = action.unreadOnly;
        });
        case actionTypes.NOTIFICATIONS_COMMENT_LOADED:
            return produce(state, draft => {
                draft.comments = action.comments.map((c,i) => {
                    return {...c, even: i % 2 === 0}
                });
                draft.isLoadingComments = false;
                draft.commentTrials = action.commentTrials.map(c => new CommentTrialModel(c.id, c.name, c.trialIdentifier, c.sponsorCompany, 0, 0));
                draft.totalPages = action.totalPages;
                draft.totalComments = action.totalComments;
                draft.authors = action.authors;
            });
    default:
        return state;
    }
};

export default notificationsReducer;